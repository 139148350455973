@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?1jndn1');
  src:  url('../fonts/icomoon.eot?1jndn1#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?1jndn1') format('truetype'),
    url('../fonts/icomoon.woff?1jndn1') format('woff'),
    url('../fonts/icomoon.svg?1jndn1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-X-ray:before {
  content: "\e931";
}
.icon-Blood:before {
  content: "\e932";
}
.icon-delay:before {
  content: "\e91e";
}
.icon-star-round:before {
  content: "\e91f";
}
.icon-sort:before {
  content: "\e920";
}
.icon-arrow-down:before {
  content: "\e921";
}
.icon-arrow-up:before {
  content: "\e922";
}
.icon-attendance:before {
  content: "\e926";
}
.icon-download:before {
  content: "\e927";
}
.icon-minus-circle:before {
  content: "\e928";
}
.icon-plus-circle:before {
  content: "\e929";
}
.icon-shift-scheduel:before {
  content: "\e92a";
}
.icon-logout1:before {
  content: "\e923";
}
.icon-delete:before {
  content: "\e91c";
}
.icon-progress-clock:before {
  content: "\e91d";
}
.icon-user:before {
  content: "\e91a";
}
.icon-wifi:before {
  content: "\e91b";
}
.icon-filter:before {
  content: "\e918";
}
.icon-sync:before {
  content: "\e919";
}
.icon-cell:before {
  content: "\e90c";
}
.icon-email:before {
  content: "\e90d";
}
.icon-logout:before {
  content: "\e90e";
}
.icon-cancel:before {
  content: "\e90f";
}
.icon-calendar:before {
  content: "\e90b";
}
.icon-setting:before {
  content: "\e900";
}
.icon-confrim:before {
  content: "\e901";
}
.icon-start:before {
  content: "\e902";
}
.icon-waiting:before {
  content: "\e903";
}
.icon-dashbord:before {
  content: "\e904";
}
.icon-rider:before {
  content: "\e905";
}
.icon-lab:before {
  content: "\e906";
}
.icon-biker:before {
  content: "\e907";
}
.icon-magnify:before {
  content: "\e908";
}
.icon-map-marker:before {
  content: "\e909";
}
.icon-account-group-outline:before {
  content: "\e92d";
}
.icon-calendar-arrow-right:before {
  content: "\e92e";
}
.icon-calendar-today:before {
  content: "\e92f";
}
.icon-content-copy:before {
  content: "\e930";
}
.icon-star-outline:before {
  content: "\e924";
}
.icon-star:before {
  content: "\e925";
}
.icon-access_time:before {
  content: "\e910";
}
.icon-query_builder:before {
  content: "\e910";
}
.icon-schedule:before {
  content: "\e910";
}
.icon-settings:before {
  content: "\e911";
}
.icon-create:before {
  content: "\e912";
}
.icon-mode_edit:before {
  content: "\e912";
}
.icon-edit:before {
  content: "\e912";
}
.icon-check:before {
  content: "\e913";
}
.icon-keyboard_arrow_right:before {
  content: "\e914";
}
.icon-keyboard_arrow_left:before {
  content: "\e915";
}
.icon-clear:before {
  content: "\e916";
}
.icon-pacman:before {
  content: "\e92c";
}
.icon-calendar-check-o:before {
  content: "\e92b";
}
.icon-bell:before {
  content: "\e917";
}
.icon-angle-right:before {
  content: "\e90a";
}
