@charset "UTF-8";
.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

.login-page-holder {
  min-height: 100vh;
}
.login-page-holder .logo-area {
  width: calc(68% + 30px);
  height: 100vh;
  position: relative;
  background: #1d2784;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  float: left;
}
.login-page-holder .logo-area:after {
  background: url(../images/graphic.png) no-repeat 50% 50%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 1;
  opacity: 0.4;
}
.login-page-holder .logo-area .logo {
  width: 224px;
  height: 224px;
  background: #F8F8F8;
  border: 10px solid #2e3790;
  border-radius: 26px;
  padding: 32px 0;
  z-index: 2;
  position: relative;
}
.login-page-holder .logo-area .logo img {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: auto;
}

.login-panel {
  float: left;
  width: 32%;
  background: white;
  min-height: 100vh;
  margin: 0 0 0 -30px;
  position: relative;
  z-index: 1;
  border-radius: 20px 0px 0px 20px;
  padding: 60px 57px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.login-panel .form-holder h1 {
  color: #2E3790;
  font-size: 36px;
  line-height: 43px;
  padding: 0 0 19px;
  margin: 0 0 75px;
  position: relative;
}
.login-panel .form-holder h1:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 50px;
  height: 8px;
  background: #EAB75D;
}
.login-panel .login-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #8A8A8A;
  text-align: center;
  padding: 18px 30px;
}

.login-form .login-btn {
  width: 100%;
  font: 16px/53px Roboto;
  color: white;
  text-transform: uppercase;
  height: 53px;
  background: #2E3790;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  margin: 17px 0 20px;
  border: none;
}
.login-form .login-btn:hover {
  background: #1c2263;
}
.login-form .issue-link, .login-form .new-id-link {
  display: block;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}
.login-form .issue-link a, .login-form .new-id-link a {
  text-decoration: none;
}
.login-form .issue-link a:hover, .login-form .new-id-link a:hover {
  text-decoration: underline;
}
.login-form .issue-link {
  margin: 0 0 13px;
}
.login-form .issue-link a {
  color: #E55353;
}
.login-form .new-id-link a {
  color: #2E3790;
}

.navbar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: white;
  width: 81px;
  padding: 21px 17px 17px 17px;
  z-index: 99;
}
.navbar .logo {
  margin: 0 auto 30px;
  width: 51px;
}
.navbar .logo img {
  display: block;
  width: 100%;
  height: auto;
}
.navbar .nav {
  display: block;
  height: calc(100vh - 190px);
  margin: 0 -20px 10px;
  padding: 0;
}
.navbar .nav::-webkit-scrollbar {
  display: none;
}
.navbar .nav > li {
  margin: 0 0 15px;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  position: relative;
}
.navbar .nav > li:hover > a {
  background: #2E3790;
  color: white;
}
.navbar .nav > li:hover .dropdown {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s linear;
}
.navbar .nav > li.active > a {
  color: white;
  background: #2E3790;
}
.navbar .nav > li > a {
  display: block;
  padding: 12px 5px;
  text-decoration: none;
  border-radius: 12px;
  color: #c9c9c9;
  margin: 0 auto;
  max-width: 47px;
}
.navbar .nav > li > a:hover {
  background: #2E3790;
  color: white;
}
.navbar .nav > li > a i {
  display: block;
}
.navbar .nav .dropdown {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 100%;
  top: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.16);
  transition: all 0.2s linear;
  margin-left: -5px;
  z-index: 999;
}
.navbar .nav .dropdown ul {
  margin: 0;
  list-style: none;
  white-space: nowrap;
  padding: 10px;
  letter-spacing: -6px;
}
.navbar .nav .dropdown ul li {
  display: inline-block;
  vertical-align: middle;
  padding: 0 5px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0;
}
.navbar .nav .dropdown ul li + li {
  border-left: 1px solid rgba(112, 112, 112, 0.08);
}
.navbar .nav .dropdown ul li.active a {
  color: white;
  background: #2E3790;
}
.navbar .nav .dropdown ul li a {
  display: block;
  padding: 12px 5px;
  text-decoration: none;
  border-radius: 12px;
  color: #545454;
  margin: 0 auto;
  min-width: 70px;
}
.navbar .nav .dropdown ul li a .ico-holder {
  min-height: 30px;
  font-size: 24px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .nav .dropdown ul li a .text {
  display: block;
}
.navbar .nav .dropdown ul li a:hover {
  background: #2E3790;
  color: white;
}
.navbar .nav .dropdown ul li a i {
  display: block;
}
.navbar .nav-footer {
  padding: 15px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.navbar .nav-footer .notification-link {
  display: block;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  margin: 0 auto 15px;
  background: #EBEBEB;
  color: #3A46B5;
  text-align: center;
  line-height: 41px;
  font-size: 20px;
  text-decoration: none;
}
.navbar .profile-area {
  position: relative;
}
.navbar .profile-area.open .profile-drop {
  display: block;
}
.navbar .profile-area .profile-drop {
  position: absolute;
  right: -165px;
  bottom: 0;
  width: 154px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  display: block;
  box-shadow: 0px 3px 14px #00000029;
  text-align: left;
  display: none;
}
.navbar .profile-area .profile-drop .drop-head {
  background: #3D49B9;
  padding: 15px;
}
.navbar .profile-area .profile-drop .drop-head .name {
  display: block;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
}
.navbar .profile-area .profile-drop .drop-head .admin {
  color: #C3C7E9;
  font-size: 12px;
  line-height: 14px;
  display: block;
}
.navbar .profile-area .links {
  padding: 0;
  margin: 0;
  list-style: none;
  padding: 10px 15px;
}
.navbar .profile-area .links li {
  padding: 5px 0;
  font-size: 12px;
  line-height: 16px;
}
.navbar .profile-area .links li a {
  color: #545454;
  text-decoration: none;
}
.navbar .profile-area .links li a i {
  display: inline-block;
  vertical-align: middle;
  min-width: 15px;
}
.navbar .profile-area img {
  display: block;
  width: 50px;
  margin: 0 auto 10px;
  border-radius: 100%;
}

.map-rider-list-panel {
  background: #f1f1f1;
  height: 100vh;
  width: 380px;
  padding: 20px 15px;
  position: fixed;
  top: 0;
  right: -380px;
  overflow: auto;
  z-index: 91;
  transition: all 0.3s ease-in-out;
}
.map-rider-list-panel h2 {
  color: #545454;
  font: 18px/17px Roboto;
  margin: 0 0 12px;
}
.map-rider-list-panel .map-rider-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.map-rider-list-panel .map-rider-list li {
  font-size: 14px;
  line-height: 17px;
}
.map-rider-list-panel .map-rider-list li strong {
  font-size: 16px;
}

.dashbord-order-list-panel {
  float: right;
  background: white;
  height: 100vh;
  width: 260px;
  padding: 5px 15px;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-list-panel {
    width: 200px;
  }
}
.dashbord-order-list-panel h3 {
  color: #545454;
  font: 14px/17px Roboto;
  margin: 0 0 12px;
}

.later-tabs {
  padding: 0;
  margin: 0 -15px;
  list-style: none;
  letter-spacing: -0.32em;
  border-bottom: 1px solid #EAE4E4;
}
.later-tabs li {
  letter-spacing: normal;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  text-align: center;
  font-size: 14px;
}
.later-tabs li.active a {
  font-weight: bold;
  border-color: #2E3790;
  color: #2E3790;
}
.later-tabs li a {
  display: block;
  border-bottom: 5px solid transparent;
  padding: 10px;
  color: #545454;
  text-decoration: none;
}

.dashbord-delay-list {
  padding: 0;
  margin: 0;
  overflow: auto;
  list-style: none;
  max-height: calc(100vh - 145px);
}
.dashbord-delay-list li {
  background: #F8F6F6;
  border-radius: 12px;
  padding: 13px;
  margin: 0 0 10px;
}
.dashbord-delay-list .id-no {
  display: inline-block;
  vertical-align: top;
  padding: 6px 12px;
  color: #979797;
  font-size: 12px;
  line-height: 16px;
  background: #E5E5E5;
  margin: 0 0 10px;
  border-radius: 12px;
}
.dashbord-delay-list .name {
  display: block;
  margin: 0 0 10px;
  color: #2E3790;
  position: relative;
  padding: 0 0 0 31px;
  font-size: 14px;
  line-height: 20px;
}
.dashbord-delay-list .name:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\e905";
  font: 18px/20px icomoon;
}
.dashbord-delay-list .delay-holder {
  overflow: hidden;
}
.dashbord-delay-list .delay-holder .by {
  float: left;
  color: #545454;
  position: relative;
  padding: 0 0 0 31px;
  font-size: 12px;
  line-height: 20px;
}
.dashbord-delay-list .delay-holder .by:after {
  position: absolute;
  left: 0;
  top: 0;
  content: "\e91e";
  font: 18px/20px icomoon;
}
.dashbord-delay-list .delay-holder .time {
  float: right;
  padding: 4px 12px;
  border-radius: 15px;
  background: #E55353;
  color: white;
  line-height: 14px;
  font-size: 12px;
  font-weight: bold;
}

.dashbord-order-list {
  padding: 0;
  margin: 0;
  overflow: auto;
  list-style: none;
  max-height: calc(100vh - 145px);
}
.dashbord-order-list > li {
  background: #F8F6F6;
  border-radius: 12px;
  padding: 13px 13px 5px;
  margin: 0 0 10px;
}
.dashbord-order-list .head {
  overflow: hidden;
  margin: 0 0 18px;
}
.dashbord-order-list .head .time {
  float: right;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
  padding: 6px 9px;
}
.dashbord-order-list .order-id {
  float: left;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
  padding: 6px 9px;
  border-radius: 12px;
  background: #E5E5E5;
}
.dashbord-order-list .order-id.inline {
  display: inline-block;
  vertical-align: top;
  margin: 0 0 15px;
  float: none;
}
.dashbord-order-list .cell-info {
  display: block;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  color: #545454;
  margin: 0 0 10px;
}
.dashbord-order-list .cell-info .icon-cell {
  float: right;
  color: #4FB73B;
}
.dashbord-order-list .rider-name {
  display: block;
  font: 16px/19px Roboto;
  color: #545454;
  margin: 0 0 8px;
}
.dashbord-order-list .rider-name span {
  font-weight: bold;
}
.dashbord-order-list .address, .dashbord-order-list .lab-test {
  position: relative;
  padding: 0 0 0 18px;
  font-size: 13px;
  line-height: 17px;
  color: #979797;
  display: block;
  margin: 0 0 11px;
}
.dashbord-order-list .address img, .dashbord-order-list .lab-test img {
  position: absolute;
  left: 0;
  top: 2px;
}
.dashbord-order-list .address .tag, .dashbord-order-list .lab-test .tag {
  display: inline-block;
  padding: 3px 9px;
  margin: 0 5px 5px 0;
  color: #545454;
  background: #FFF;
  border-radius: 12px;
}

.dashbord-content {
  overflow: hidden;
  padding: 0 35px;
}

.map-holder {
  margin: 0 -35px;
  background: #E5E5E5;
  position: relative;
  height: calc(100vh - 420px);
}
.map-holder .full-map-btn {
  position: absolute;
  left: 49px;
  top: 34px;
  background: #EAB75D;
  box-shadow: 0 0 0 8px rgba(234, 183, 93, 0.47);
  width: 185px;
  height: 50px;
  border-radius: 50px;
  padding: 15px 20px;
  line-height: 20px;
  color: #2E3790;
  font-size: 11px;
  text-decoration: none;
  text-align: right;
}
.map-holder .full-map-btn .switch {
  position: absolute;
  width: 54px;
  height: 54px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 1px 3px #00000026;
  left: 0;
  top: -2px;
  transition: all 0.25s ease-in-out;
}
.map-holder .full-map-btn .switch img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.map-holder .full-map-btn .switch .map-view {
  display: none;
}

.dashbord-order-panel {
  border-radius: 12px;
  background: white;
  padding: 20px 17px;
  overflow: hidden;
  margin: 0 0 16px;
}
.dashbord-order-panel .order-area {
  float: left;
  width: 230px;
  margin: 0 60px 0 0;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-panel .order-area {
    width: 175px;
    margin: 0 20px 0 0;
  }
}
.dashbord-order-panel .order-area .col {
  float: left;
  width: 50%;
  padding: 0 10px;
  text-align: right;
}
.dashbord-order-panel .order-area .col:first-child {
  text-align: left;
}
.dashbord-order-panel .order-area .title {
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: #545454;
  margin: 0 0 24px;
}
.dashbord-order-panel .order-area img {
  display: block;
  margin: 0 0 0 3px;
}
.dashbord-order-panel .order-area .view-more {
  margin: 0 0 35px;
}
.dashbord-order-panel .order-area .order-title {
  display: block;
  font-size: 28px;
  line-height: 22px;
  font-weight: 500;
  color: #545454;
}
.dashbord-order-panel .order-area .order-title span {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.dashbord-order-panel .esclated-tag {
  border-radius: 12px;
  background: #E55353;
  width: 187px;
  height: 103px;
  float: right;
  padding: 30px 19px;
  margin: 5px;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-panel .esclated-tag {
    width: 145px;
  }
}
.dashbord-order-panel .esclated-tag img {
  float: left;
}
.dashbord-order-panel .esclated-tag .info {
  overflow: hidden;
  text-align: right;
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}
.dashbord-order-panel .esclated-tag .info span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.dashbord-order-panel .esclated-tag .info span a {
  color: white;
  text-decoration: none;
}
.dashbord-order-panel .unverified-tag {
  border-radius: 12px;
  background: #FFDA60;
  width: 187px;
  height: 103px;
  float: right;
  padding: 30px 19px;
  margin: 5px;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-panel .unverified-tag {
    width: 145px;
  }
}
.dashbord-order-panel .unverified-tag img {
  float: left;
}
.dashbord-order-panel .unverified-tag .info {
  overflow: hidden;
  text-align: right;
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  display: block;
}
.dashbord-order-panel .unverified-tag .info span {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.dashbord-order-panel .unverified-tag .info span a {
  color: white;
  text-decoration: none;
}
.dashbord-order-panel .order-progress {
  overflow: hidden;
  padding: 0 30px 0 10px;
  margin: 0;
  list-style: none;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-panel .order-progress {
    padding: 0 10px 0 10px;
  }
}
.dashbord-order-panel .order-progress:after {
  position: absolute;
  left: 0px;
  top: 55px;
  bottom: 0;
  content: "";
  border-left: 1px solid #c9c9c9;
}
.dashbord-order-panel .order-progress li {
  float: left;
  width: 33.33%;
  padding: 0 15px;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-panel .order-progress li {
    padding: 0 5px;
  }
}
.dashbord-order-panel .order-progress li:last-child:after {
  display: none;
}
.dashbord-order-panel .order-progress li:after {
  position: absolute;
  left: 51px;
  right: -8px;
  top: 15px;
  content: "";
  border-bottom: 2px dashed #c9c9c9;
}
@media screen and (max-width: 1300px) {
  .dashbord-order-panel .order-progress li:after {
    left: 40px;
    right: 0;
  }
}
.dashbord-order-panel .order-progress li i {
  display: block;
  font-size: 30px;
  line-height: 30px;
  color: #c9c9c9;
  margin: 0 0 34px;
}
.dashbord-order-panel .order-progress li .number {
  display: block;
  font-size: 18px;
  color: #545454;
  font-weight: 500;
  line-height: 18px;
}
.dashbord-order-panel .order-progress li .number span {
  display: block;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.graph-rider-panel {
  overflow: hidden;
}

.graph-panel {
  border-radius: 12px;
  background: white;
  width: 65%;
  float: left;
  padding: 26px;
}
.graph-panel .graph {
  width: calc(100% - 250px);
  float: right;
  max-width: 410px;
}
.graph-panel .graph-info {
  overflow: hidden;
}
.graph-panel .graph-info .title {
  font-size: 16px;
  line-height: 18px;
  color: #545454;
  font-weight: bold;
  display: block;
  margin: 0 0 20px;
}
.graph-panel .graph-info .collection-table tr th, .graph-panel .graph-info .collection-table tr td {
  padding: 10px;
  padding-left: 15px;
  vertical-align: bottom;
  border: 0px;
}
.graph-panel .graph-info .collection-table tr th:first-child, .graph-panel .graph-info .collection-table tr td:first-child {
  padding-left: 0;
}
.graph-panel .graph-info .collection-table.table tr th:first-child, .graph-panel .graph-info .collection-table.table tr td:first-child {
  padding-left: 5px;
}
.graph-panel .graph-info .collection-table .title {
  font-size: 16px;
  line-height: 18px;
  color: #545454;
  font-weight: bold;
  display: block;
  margin: 0;
}
.graph-panel .graph-info .collection-table .box {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: #2E3790;
  margin: 0 0 8px;
}
.graph-panel .graph-info .collection-table .box.traffic {
  background: #EDA648;
}
.graph-panel .graph-info .collection-table .box.too-many {
  background: #EDC057;
}
.graph-panel .graph-info .collection-table .box.Problem {
  background: #FFDA60;
}
.graph-panel .graph-info .collection-table .text {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #545454;
}
.graph-panel .graph-info .collection-table .value {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #545454;
  line-height: 20px;
  padding-top: 0;
}

.graph-detail {
  padding: 0;
  margin: 0 0 -18px;
  list-style: none;
}
.graph-detail li {
  overflow: hidden;
  padding: 0 0 18px;
}
.graph-detail li .left-info {
  float: left;
  min-width: 175px;
}
@media screen and (max-width: 1300px) {
  .graph-detail li .left-info {
    min-width: 78%;
  }
}
.graph-detail li .box {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: #2E3790;
  margin: 0 0 8px;
}
.graph-detail li .box.traffic {
  background: #EDA648;
}
.graph-detail li .box.too-many {
  background: #EDC057;
}
.graph-detail li .box.Problem {
  background: #FFDA60;
}
.graph-detail li .text {
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #545454;
}
.graph-detail li .value {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #545454;
  line-height: 20px;
  padding-top: 10px;
}

.dashbord-rider-panel {
  float: right;
  width: calc(35% - 16px);
  border-radius: 12px;
  background: white;
  padding: 25px;
}
.dashbord-rider-panel .head {
  overflow: hidden;
  margin: 0 0 28px;
}
.dashbord-rider-panel .head .title {
  float: left;
  font-size: 16px;
  line-height: 18px;
  color: #545454;
}
.dashbord-rider-panel .head .view-more {
  float: right;
}
.dashbord-rider-panel .rider-status {
  padding: 0;
  margin: 0 0 -28px;
  list-style: none;
}
.dashbord-rider-panel .rider-status li {
  overflow: hidden;
  padding: 0 0 38px;
}
.dashbord-rider-panel .rider-status li img {
  float: left;
  font-size: 50px;
}
.dashbord-rider-panel .rider-status li .numbering {
  float: right;
  font-size: 28px;
  line-height: 28px;
  color: #545454;
  font-weight: 500;
  text-align: right;
  padding: 5px 0 0;
}
.dashbord-rider-panel .rider-status li .numbering span {
  display: block;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}

.map-full .map-right-info {
  right: 145px;
}
.map-full .map-right-info.full-height {
  right: 400px;
}
.map-full .map-rider-list-panel {
  right: 0;
}
.map-full .map-holder.full-height {
  height: 100vh;
}
.map-full .map-holder > div {
  height: 100vh !important;
  width: calc(100% - 120px) !important;
  position: relative;
}
@media screen and (max-width: 1300px) {
  .map-full .map-holder > div {
    width: calc(100% - 180px) !important;
  }
}
.map-full .map-holder .full-map-btn {
  text-align: left;
}
.map-full .map-holder .full-map-btn .switch {
  left: 131px;
}
.map-full .map-holder .full-map-btn .switch .map-view {
  display: block;
}
.map-full .map-holder .full-map-btn .switch .map-sm {
  display: none;
}

.map-holder {
  margin: 0 -35px;
  background: #E5E5E5;
  position: relative;
  height: calc(100vh - 420px);
  z-index: 9;
}
.map-holder > div {
  height: calc(100vh - 420px);
  transition: all 0.3s ease-in-out;
}
.map-holder .full-map-btn {
  position: absolute;
  left: 49px;
  top: 64px;
  background: #EAB75D;
  box-shadow: 0 0 0 8px rgba(234, 183, 93, 0.47);
  width: 185px;
  height: 50px;
  border-radius: 50px;
  padding: 15px 20px;
  line-height: 20px;
  color: #2E3790;
  font-size: 11px;
  text-decoration: none;
  text-align: right;
}
.map-holder .full-map-btn .switch {
  position: absolute;
  width: 54px;
  height: 54px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 1px 3px #00000026;
  left: 0;
  top: -2px;
  transition: all 0.25s ease-in-out;
}
.map-holder .full-map-btn .switch img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.map-holder .full-map-btn .switch .map-view {
  display: none;
}

.top-content {
  position: relative;
}

.map-right-info {
  position: absolute;
  right: 35px;
  top: 0;
  z-index: 91;
  transition: all 0.3s ease-in-out;
}

.id-search-form {
  position: absolute;
  bottom: 30px;
  right: 35px;
  box-shadow: 0px 1px 3px #00000026;
  background: white;
  padding: 0 0 0 22px;
  border: none;
  width: 362px;
}

.leaflet-container {
  z-index: -10000;
}

.sort-rider-order {
  float: left;
  padding: 16px 0;
  margin: 0;
  list-style: none;
  background: white;
  border-radius: 0 0 12px 12px;
  margin: 0 0 0 15px;
}
.sort-rider-order li {
  float: left;
  width: 74px;
  text-align: center;
  padding: 0 10px;
  border-left: 1px solid rgba(65, 61, 61, 0.2);
}
.sort-rider-order li:first-child {
  border: none;
}
.sort-rider-order li .title {
  display: block;
  color: #545454;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 5px;
}
.sort-rider-order.filetr {
  float: none;
  margin: 0;
  padding: 0;
}
.sort-rider-order.filetr li {
  float: none;
  overflow: hidden;
  width: 100%;
  border: none;
  padding: 0 0 30px;
}
.sort-rider-order.filetr li .title {
  float: left;
}
.sort-rider-order.filetr li .switcher {
  float: right;
}

.refresh-button {
  background: #4B57BE;
  color: white;
  outline: none !important;
  border: none;
  border-radius: 50%;
  top: 125px;
  left: 55px;
  position: absolute;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
}
.refresh-button img {
  width: 20px;
  height: 20px;
}

.full-map-btn-refresh {
  text-align: left;
}
.full-map-btn-refresh .switch {
  left: 131px;
}
.full-map-btn-refresh .switch .map-view {
  display: block;
}
.full-map-btn-refresh .switch .map-sm {
  display: none;
}

.id-search-form {
  border-bottom: 1px solid #EAE4E4;
  margin: 0 -15px 17px;
  padding: 0 15px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
  box-shadow: none;
  width: auto;
}
.id-search-form input[type=search] {
  width: 100%;
  height: 46px;
  line-height: 26px;
  padding: 10px 25px 10px 0;
  border: none;
  font-size: 16px;
  outline: none;
}
.id-search-form ::placeholder {
  color: #CECECE;
  opacity: 1;
}
.id-search-form :-ms-input-placeholder {
  color: #CECECE;
}
.id-search-form ::-ms-input-placeholder {
  color: #CECECE;
}
.id-search-form .search-btn {
  position: absolute;
  right: 15px;
  top: 13px;
  background: none;
  padding: 0;
  border: none;
  outline: none;
  color: #CECECE;
  cursor: pointer;
}
.id-search-form .search-btn:hover {
  color: #2E3790;
}

.city-dropdown {
  float: left;
  background: #4B57BE;
  border-radius: 0 0 16px 16px;
  width: 161px;
  padding: 0 6px;
  position: relative;
  z-index: 9;
  margin-top: 0;
  transition: all 0.25s ease-in-out;
}
.city-dropdown.open {
  margin-top: 0;
}
.city-dropdown.open .selected .name i {
  display: none;
}
.city-dropdown .selected {
  height: 88px;
  margin: 0 -3px;
  display: block;
  position: relative;
  padding: 0 3px 0 80px;
  text-decoration: none;
}
.city-dropdown .selected .name {
  display: inline-block;
  vertical-align: top;
  color: white;
  font-size: 14px;
  line-height: 18px;
  padding: 40px 0 0;
}
.city-dropdown .selected .name i {
  display: block;
  text-align: center;
}
.city-dropdown .selected .name i:before {
  transform: rotate(90deg);
  display: block;
}
.city-dropdown .selected .img-holder {
  position: absolute;
  left: 0;
  width: 70px;
  bottom: 0;
  text-align: center;
}
.city-dropdown .selected .img-holder img {
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  max-height: 60px;
  width: auto;
  height: auto;
}
.city-dropdown .city-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 220px;
  overflow: auto;
}
.city-dropdown .city-list li {
  margin: 0 0 3px;
}
.city-dropdown .city-list li a {
  background: #414CA5;
  height: 70px;
  text-decoration: none;
  display: block;
  padding: 3px 3px 0 70px;
  position: relative;
}
.city-dropdown .city-list li .name {
  display: inline-block;
  vertical-align: top;
  color: white;
  font-size: 12px;
  line-height: 14px;
  padding: 20px 0 0;
}
.city-dropdown .city-list li .img-holder {
  position: absolute;
  left: 0;
  width: 70px;
  bottom: 0;
  text-align: center;
}
.city-dropdown .city-list li .img-holder img {
  display: inline-block;
  vertical-align: top;
  max-width: 50%;
  max-height: 60px;
  width: auto;
  height: auto;
}
.city-dropdown .close-link {
  display: block;
  text-align: center;
  padding: 5px 0;
}
.city-dropdown .close-link a {
  color: white;
  text-decoration: none;
  font-size: 16px;
  display: block;
}
.city-dropdown .close-link a i:before {
  transform: rotate(-90deg);
  display: block;
}
.city-dropdown.white {
  background: #fff;
}
.city-dropdown.white .close-link a {
  color: #3D49B9;
}
.city-dropdown.white .city-list li .name {
  color: #3D49B9;
}
.city-dropdown.white .city-list li a {
  background: #F6F6F6;
}
.city-dropdown.white .selected .name {
  color: #3D49B9;
}

.map-popup {
  width: 260px;
  left: 40px;
  top: 100px;
  border-radius: 10px;
  padding: 22px 22px 5px;
  height: auto !important;
}
.map-popup:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 14px 11px 0;
  border-color: transparent #2E3790 transparent transparent;
  opacity: 0.87;
  position: absolute;
  left: -14px;
  top: 50%;
  content: "";
  margin: -10px 0 0;
  display: none;
}
.map-popup .time {
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: #fff;
  margin: 0 0 12px;
}
.map-popup .head {
  margin: 0 0 14px;
  overflow: hidden;
}
.map-popup .order-id {
  float: left;
  font-size: 13px;
  line-height: 15px;
  color: #fff;
  padding: 7px 10px;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.42);
}
.map-popup .action-list {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
}
.map-popup .action-list li {
  float: left;
  margin: 0 0 0 10px;
}
.map-popup .name {
  display: block;
  font-size: 16px;
  line-height: 18px;
  color: white;
  margin: 0 0 10px;
}
.map-popup .address, .map-popup .lab-test {
  position: relative;
  padding: 0 0 0 18px;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  display: block;
  margin: 0 0 11px;
}
.map-popup .address img, .map-popup .lab-test img {
  position: absolute;
  left: 0;
  top: 2px;
}
.map-popup .address .tag, .map-popup .lab-test .tag {
  display: inline-block;
  padding: 3px 9px;
  margin: 0 5px 5px 0;
  color: #545454;
  background: #EFEFEF;
  border-radius: 12px;
}
.map-popup.assign-popup {
  width: 192px;
  padding: 22px;
}
.map-popup.assign-popup .list {
  padding: 0;
  margin: 0;
  list-style: none;
  color: white;
}
.map-popup.assign-popup .list li {
  overflow: hidden;
  padding: 10px 0 0;
}
.map-popup.assign-popup .list li:first-child {
  padding: 0;
}
.map-popup.assign-popup .list li img {
  float: left;
}
.map-popup.assign-popup .list li .info {
  overflow: hidden;
  text-align: right;
}
.map-popup.assign-popup .list li span {
  display: block;
}
.map-popup.assign-popup .list li span.km {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.map-popup.assign-popup .list li span.detail {
  font-size: 9px;
  line-height: 14px;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  background: rgba(46, 55, 144, 0.87) !important;
}

.leaflet-popup-content {
  color: #FFF;
}

.leaflet-container a.leaflet-popup-close-button {
  padding: 5px 5px 0 0;
}

.order-page {
  display: flex;
  flex-direction: row;
  background: white;
}
.order-page.customer-detail {
  margin: 0 0 0 -80px;
}
.order-page.customer-detail .order-right-info {
  width: 100%;
}
.order-page.customer-detail .two-cols {
  max-width: 1000px;
  margin: 0 auto;
}
.order-page.customer-detail .two-cols .col-info {
  border: none;
}
.order-page.customer-detail .two-cols .col-info.customer {
  display: flex;
  text-align: center;
}
.order-page.customer-detail .two-cols .col-info.customer .img-hoder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.order-page.customer-detail .two-cols .col-info.customer img {
  display: inline-block;
  width: 322px;
  text-align: center;
  margin: 0 0 20px;
}
.order-page.customer-detail .two-cols .col-info.customer h3 {
  font-size: 18px;
  line-height: 24px;
  color: #000000de;
  margin: 0;
  font-weight: normal;
}
.order-page.customer-detail .order-step {
  max-width: 169px;
}
.order-page.customer-detail .customer-info-order {
  float: left;
  margin: 0 20px 0 0;
  min-width: 250px;
}
.order-page.customer-detail .customer-info-order h2 {
  margin: 0 0 2px;
  color: #545454;
  font-size: 26px;
  line-height: 32px;
  font-weight: normal;
}
.order-page.customer-detail .customer-info-order .number {
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #000000de;
}
.order-page .order-aside {
  width: 29%;
  min-height: 100vh;
  text-align: center;
  padding: 35px 35px 0;
}
.order-page .order-aside h2 {
  margin: 0 0 2px;
  font-size: 26px;
  line-height: 30px;
  color: #545454;
  font-weight: normal;
}
.order-page .order-aside .partial-order-data {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #d4d4d4;
  border-radius: 12px;
  margin-top: 12px;
}
.order-page .order-aside .partial-order-data .order-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}
.order-page .order-aside .partial-order-data .order-data .data-label {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}
.order-page .order-aside .partial-order-data .order-data .data-value {
  font-size: 14px;
  color: #333;
}
.order-page .order-aside .partial-order-data .order-data:last-child {
  border-bottom: 0px;
}
.order-page .order-aside .order-number {
  display: block;
  color: #000000de;
  font-size: 16px;
  line-height: 20px;
}
.order-page .order-aside .order-img {
  min-height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.order-page .order-aside .order-img img {
  display: inline-block;
  vertical-align: top;
  max-width: 90%;
}
.order-page .order-aside.step-two {
  padding: 35px 0 0;
  text-align: left;
}
.order-page .order-aside.step-two .head {
  padding: 0 22px 17px;
  overflow: hidden;
  border-bottom: 1px solid #d4d4d4;
}
.order-page .order-aside.step-two .head img {
  float: left;
  margin: 0 10px 0 0;
}
.order-page .order-aside.step-two .head h2 {
  padding: 20px 0 0;
}
.order-page .order-aside.step-two .order-col h2 {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 10px;
  color: #000000de;
  font-weight: normal;
}
.order-page .order-aside.step-two .order-address {
  padding: 0;
}
.order-page .order-aside.step-two .order-address .add-address {
  color: #3a46b5;
}
.order-page .order-aside.step-two .order-address .order-address-list {
  height: calc(100vh - 400px);
}
.order-page .order-right-info {
  border-left: 1px solid #d4d4d4;
  width: 71%;
}
.order-page .order-right-info .head {
  overflow: hidden;
  border-bottom: 1px solid #d4d4d4;
  padding: 30px;
}
.order-page .order-right-info .order-step {
  float: left;
  width: 171px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.order-page .order-right-info .order-step li {
  position: relative;
}
.order-page .order-right-info .order-step li:first-child {
  float: left;
}
.order-page .order-right-info .order-step li:first-child:after {
  display: none;
}
.order-page .order-right-info .order-step li.active:after {
  border-color: #3f4cbc;
}
.order-page .order-right-info .order-step li.active .step-number {
  border-color: #3f4cbc;
  color: #3f4cbc;
}
.order-page .order-right-info .order-step li.done:after {
  border-color: #3f4cbc;
}
.order-page .order-right-info .order-step li.done .step-number {
  border-color: #3f4cbc;
  color: white;
  background: #3f4cbc;
  font-size: 0;
}
.order-page .order-right-info .order-step li.done .step-number:before {
  content: "\e913";
  font: 16px/43px icomoon;
}
.order-page .order-right-info .order-step li:after {
  position: absolute;
  top: 22px;
  border-bottom: 1px solid #d4d4d4;
  content: "";
  left: -80px;
  right: 10px;
}
.order-page .order-right-info .order-step li .step-number {
  display: block;
  width: 45px;
  height: 45px;
  border: 1px solid #d4d4d4;
  border-radius: 50%;
  margin: 0 auto 5px;
  text-align: center;
  font-size: 16px;
  line-height: 43px;
  position: relative;
  z-index: 1;
  background: white;
  color: #d4d4d4;
}
.order-page .order-right-info .order-step li .text {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: #0000008a;
}
.order-page .order-right-info .btn-list {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
}
.order-page .order-right-info .btn-list li {
  float: left;
  margin: 0 0 0 11px;
}
.order-page .order-right-info .btn-list li a {
  text-decoration: none;
}
.order-page .order-right-info .btn-list li button {
  display: block;
  min-width: 161px;
  border-radius: 6px;
  background: #4f5cd3;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 1200px) {
  .order-page .order-right-info .btn-list li button {
    min-width: 125px;
  }
}
.order-page .order-right-info .btn-list li button:hover {
  opacity: 0.8;
}
.order-page .order-right-info .btn-list li button.disable {
  cursor: not-allowed;
}
.order-page .order-right-info .btn-list li button.red {
  background: #e55353;
}
.order-page .order-right-info .btn-list li button.yellow {
  background: #eab75d;
}
.order-page .order-right-info .btn-list li button.gray {
  background: #b8b8b8;
}
.order-page .order-right-info .btn-list li button.green {
  background: #4fb73b;
}
.order-page .order-right-info .btn-list li button.next:after {
  position: absolute;
  right: 10px;
  top: 15px;
  content: "\e914";
  font: 24px/24px icomoon;
}
.order-page .order-right-info .btn-list li button.back:after {
  position: absolute;
  left: 10px;
  top: 15px;
  content: "\e915";
  font: 24px/24px icomoon;
}
.order-page .two-cols {
  display: flex;
  flex-direction: row;
}
.order-page .two-cols .col-info {
  width: 50%;
  border-left: 1px solid #d4d4d4;
  height: calc(100vh - 127px);
  overflow-y: auto;
}
.order-page .two-cols .col-info:first-child {
  border: none;
}
.order-page .form-row {
  padding: 0 0 35px;
}
.order-page .form-row.autocomplete {
  padding: 0;
  margin: 0 0 35px;
  position: relative;
}
.order-page .form-row.autocomplete div {
  display: block !important;
  display: block !important;
  z-index: 99;
  max-height: 150px !important;
  overflow: auto !important;
  box-shadow: none !important;
}
.order-page .form-row.autocomplete div div div {
  padding: 0 10px;
  line-height: 30px;
  font-size: 16px;
}
.order-page .form-row.autocomplete input {
  border-width: 0 0 1px;
  border-color: #e8e8e8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #000000de;
  outline: none;
}
.order-page .form-row.autocomplete input + div {
  border: none;
  left: 0 !important;
  top: 100% !important;
  position: absolute !important;
  right: 0 !important;
}
.order-page input[type=text],
.order-page input[type=password],
.order-page input[type=tel] {
  border-width: 0 0 1px;
  border-color: #e8e8e8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #000000de;
  outline: none;
}
.order-page label,
.order-page .label-text {
  display: inline-block;
  color: #0000008a;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.order-page textarea {
  height: 135px;
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
}
.order-page h2 {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 49px;
  color: #000000de;
  font-weight: normal;
}

.customer-info {
  padding: 30px 22px;
}
.customer-info .select {
  border-bottom: 1px solid #e8e8e8;
}
.customer-info select {
  background: none;
  border: none;
  margin: 0 0 0 -6px;
}
.customer-info .cust-head {
  overflow: hidden;
  margin: 0 0 49px;
}
.customer-info .cust-head .right {
  float: right;
}
.customer-info .cust-head .right a {
  float: left;
  margin: 0 0 0 10px;
  text-decoration: none;
  color: #000;
  font-size: 20px;
}
.customer-info .cust-head .right a:hover {
  color: #0275d8;
}
.customer-info .cust-head h2 {
  float: left;
  margin: 0;
}
.customer-info .radio-list {
  overflow: hidden;
  padding: 0;
  margin: 0;
  list-style: none;
  margin: 0 0 -10px;
}
.customer-info .radio-list li {
  float: left;
  padding: 0 30px 10px 0;
}
.customer-info .radio-list li label {
  position: relative;
  padding: 0 0 0 33px;
}
.customer-info .radio-list li label input {
  position: fixed;
  left: -9999px;
  top: -9999px;
}
.customer-info .radio-list li label input:checked ~ .fake-radio {
  border-color: #3f4cbc;
}
.customer-info .radio-list li label input:checked ~ .fake-radio:after {
  display: block;
}
.customer-info .radio-list li label .fake-radio {
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #010101;
}
.customer-info .radio-list li label .fake-radio:after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  background: #3f4cbc;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: -6px 0 0 -6px;
  display: none;
}
.customer-info .form-area {
  overflow: auto;
  height: calc(100vh - 340px);
}
.customer-info .btn-holder {
  margin: 0 -4px;
  padding: 30px 0 0;
  list-style: none;
  letter-spacing: -0.32em;
}
.customer-info .btn-holder li {
  letter-spacing: normal;
  padding: 0 4px;
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.customer-info .btn-holder li button {
  display: block;
  width: 100%;
  border-radius: 6px;
  background: #4fb73b;
  border: 1px solid transparent;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  border: none;
  cursor: pointer;
}
.customer-info .btn-holder li button:hover {
  opacity: 0.8;
}
.customer-info .btn-holder li button.cancel {
  background: white;
  border: 1px solid #c0c0c0;
  color: #c0c0c0;
}

.test-info {
  padding: 0 22px;
}
.test-info h2 {
  margin: 0 0 10px;
}
.test-info .head-info {
  margin: 0 -22px 30px;
  border-bottom: 1px solid #d4d4d4;
}
.test-info .head-info:after {
  display: block;
  clear: both;
  content: "";
}
.test-info .head-info .head-col {
  float: left;
  width: 50%;
  padding: 14px 22px;
  border-left: 1px solid #d4d4d4;
  min-height: 82px;
}
.test-info .head-info .head-col:first-child {
  border: none;
}
.test-info .date-picker {
  position: relative;
}
.test-info .date-picker input {
  border: none;
}
.test-info .value {
  display: block;
  font-size: 16px;
  line-height: 18px;
  padding: 9px 0 0;
}
.test-info input[type=number] {
  border-width: 0 0 1px;
  border-color: #e8e8e8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: #000000de;
  outline: none;
}
.test-info .form-row {
  padding: 0 0 10px;
}
.test-info .delivery-box {
  margin: 0 -22px 5px;
  padding: 22px;
  border: 1px solid #d4d4d4;
  border-width: 1px 0;
}

.order-map {
  background: #545454;
  position: relative;
  height: calc(100vh - 127px);
}

.order-address {
  padding: 33px 22px 10px;
}
.order-address h2 {
  margin: 0 0 20px;
}
.order-address .add-address {
  display: block;
  border: 2px dashed #d0d0d0;
  padding: 19px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: #3f4cbc;
}
.order-address .add-address:before {
  content: "+";
  font-weight: bold;
  padding: 0 10px 0 0;
}

.test-col {
  padding: 30px 22px 0;
}
.test-col h2 {
  margin: 0 0 33px;
}
.test-col .no-test-area {
  height: calc(100vh - 365px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.test-col .no-test-area img {
  width: 132px;
}
.test-col .selected-tag {
  height: calc(100vh - 364px);
}
.test-col .no-data {
  display: none;
}
.test-col .test-total {
  background: #f9f9f9;
  padding: 22px;
  margin: 0 -22px;
}
.test-col .test-total .title {
  display: block;
  color: #0000008a;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 7px;
}
.test-col .price-holder {
  overflow: hidden;
  color: #bcbcbc;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}
.test-col .price-holder .amo {
  float: left;
}
.test-col .price-holder .pkr {
  float: right;
}

.order-col {
  padding: 30px 22px 0;
}
.order-col h2 {
  margin: 0 0 9px;
}
.order-col .value {
  position: relative;
}
.order-col .value .rs {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  opacity: 0.46;
  font-size: 16px;
  font-weight: bold;
}
.order-col .value.select {
  border-bottom: 1px solid #e8e8e8;
}
.order-col .value.select select {
  background: none;
  border: none;
  margin: 0 0 0 -6px;
}
.order-col .date-picker {
  position: relative;
}
.order-col .date-picker .icon-calendar {
  position: absolute;
  right: 0;
  top: 0;
  color: #3f4cbc;
}
.order-col .date-picker .MuiTextField-root {
  display: block;
}
.order-col .date-picker .MuiTextField-root > div {
  display: block;
}
.order-col .date-picker .MuiTextField-root > div:after, .order-col .date-picker .MuiTextField-root > div:before {
  display: none;
}
.order-col .delivery-box {
  margin: 0 -22px 5px;
  padding: 0 22px 22px 22px;
  border: 1px solid #d4d4d4;
  border-width: 0 0 1px;
}
.order-col .test-total {
  background: #e8eaff;
  padding: 22px;
  margin: 0 -22px;
}
.order-col .test-total .title {
  display: block;
  color: #3f4cbc;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 7px;
}
.order-col .price-holder {
  overflow: hidden;
  color: #3f4cbc;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
}
.order-col .price-holder .amo {
  float: left;
}
.order-col .price-holder .pkr {
  float: right;
}

.customer-colum {
  padding: 30px 22px 30px;
}
.customer-colum h2 {
  margin: 0 0 20px;
}
.customer-colum .sub-title {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #0000008a;
  margin: 0 0 10px;
}
.customer-colum .add-patient {
  display: block;
  border: 2px dashed #d0d0d0;
  padding: 19px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: #3f4cbc;
  border-radius: 5px;
}
.customer-colum .add-patient:before {
  content: "+";
  font-weight: bold;
  padding: 0 10px 0 0;
}
.customer-colum .adding-patient-btn {
  display: block;
  background: #e8eaff;
  padding: 20px 26px;
  position: relative;
  font-size: 18px;
  line-height: 22px;
  color: rgba(63, 76, 188, 0.6);
  border-radius: 10px;
  text-decoration: none;
}
.customer-colum .adding-patient-btn:after {
  position: absolute;
  right: 30px;
  content: "\e91d";
  font: 25px/30px icomoon;
  color: #3f4cbc;
  margin: -15px 0 0;
  top: 50%;
}
.customer-colum .id-search-form {
  margin: 0 0 17px 0;
}

.patient-list {
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
  overflow: auto;
  height: calc(100vh - 420px);
}
.patient-list li {
  border: 1px solid #d0d0d0;
  margin: 0 0 12px;
  padding: 20px 30px;
  border-radius: 10px;
  position: relative;
}
.patient-list li:hover {
  background: #f8f8f8;
}
.patient-list li img {
  position: absolute;
  right: 40px;
  top: 50%;
  margin: -16px 0 0;
  width: 20px;
  display: none;
}
.patient-list li.selected {
  border-color: #3f4cbc;
  background: #e8eaff;
}
.patient-list li.selected img {
  display: block;
}
.patient-list .name {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #545454;
}
.patient-list .age,
.patient-list .card-number {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #909090;
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 4px;
}

.no-selected-patient {
  background: #f8f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.no-selected-patient img {
  width: 158px;
}

.selected-patient-info {
  background: #f8f6f6;
}
.selected-patient-info .head {
  background: white;
  overflow: hidden;
  padding: 25px;
}
.selected-patient-info .head h2 {
  margin: 0;
  float: left;
}
.selected-patient-info .head .links {
  float: right;
}
.selected-patient-info .head .links a {
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  float: left;
  margin: 0 0 0 20px;
  position: relative;
  padding: 0 0 0 24px;
}
.selected-patient-info .head .links a:after {
  position: absolute;
  left: 0;
  top: 0;
  font: 20px/24px icomoon;
}
.selected-patient-info .head .links a.delete {
  color: #e55353;
}
.selected-patient-info .head .links a.delete:after {
  content: "\e91c";
}
.selected-patient-info .head .links a.update {
  color: #4f5cd3;
}
.selected-patient-info .head .links a.update:after {
  content: "\e912";
}
.selected-patient-info .last-order {
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  background: #ebebeb;
  border-radius: 12px;
  padding: 25px;
  margin: 0 25px;
}
.selected-patient-info .last-order .date {
  float: right;
}
.selected-patient-info .last-order .title {
  float: left;
}
.selected-patient-info .patient-info-list {
  padding: 0;
  margin: 0;
  list-style: none;
  height: calc(100vh - 300px);
  overflow: auto;
}
.selected-patient-info .patient-info-list li {
  padding: 25px;
  border-top: 1px solid #d4d4d4;
}
.selected-patient-info .patient-info-list li:first-child {
  border: none;
}
.selected-patient-info .patient-info-list li .name {
  display: block;
  color: #000000de;
  font-size: 16px;
  line-height: 24px;
}
.selected-patient-info .patient-info-list li .label-text {
  display: block;
  color: #0000008a;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 5px;
}

.filter-active .filters-slide {
  right: 0;
  transition: all 0.25s ease-in-out;
}
.filter-active .listing-page {
  padding: 41px 300px 10px 30px;
}
@media screen and (max-width: 1200px) {
  .filter-active .listing-page {
    padding: 41px 300px 10px 30px;
  }
}

.listing-page {
  padding: 41px 30px 10px 30px;
  transition: all 0.25s ease-in-out;
}
.listing-page .listing-head {
  margin: 0 0 20px;
}
.listing-page .listing-head:after {
  display: block;
  clear: both;
  content: "";
}
.listing-page .listing-head h2 {
  font-size: 26px;
  line-height: 32px;
  margin: 4px 0;
  color: #545454;
  float: left;
  font-weight: normal;
}
.listing-page .listing-head .order-btn {
  float: right;
  background-color: #EAB75D;
  color: white;
  border-radius: 6px;
  min-width: 143px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
}
.listing-page .listing-head .order-btn:before {
  content: "+";
  font-weight: bold;
  padding: 0 10px 0 0;
}
.listing-page .listing-head .order-btn.create {
  min-width: 232px;
  padding: 15px 10px;
  background: #4F5CD3;
  font-size: 16px;
  margin: -5px 0 0;
}
.listing-page .listing-head .order-btn.create:before {
  display: none;
}
.listing-page .listing-head .order-btn.download:before {
  display: none;
}

.list-search-area {
  margin: 0 0 40px;
}
.list-search-area:after {
  display: block;
  clear: both;
  content: "";
}
.list-search-area .id-search-form {
  float: left;
  border: none;
  margin: 0;
  box-shadow: 0px 1px 3px #00000014;
  border-radius: 7px;
  background: white;
  width: calc(100% - 56px);
}
.list-search-area .id-search-form.refresh {
  width: calc(100% - 112px);
}
.list-search-area .filter-btn {
  width: 46px;
  height: 46px;
  border-radius: 7px;
  box-shadow: 0px 1px 3px #00000014;
  color: #B7B7B7;
  text-align: center;
  font: 14px/46px icomoon;
  float: right;
  background: white;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}
.list-search-area .filter-btn:hover {
  color: #3A46B5;
}

.indicators-holder {
  display: flex;
  align-items: center;
}
.indicators-holder .count {
  font-weight: bold;
  min-width: 25px;
  display: flex;
  color: #545454;
}
.indicators-holder .indicators {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}
.indicators-holder .indicators li {
  margin: 0 5px;
}
.indicators-holder .indicators li .indicator {
  display: block;
  width: 20px;
  height: 20px;
  background: #4FB73B;
  border-radius: 100%;
}
.indicators-holder .indicators li.yellow .indicator {
  background: #EAB75D;
}
.indicators-holder .indicators li.red .indicator {
  background: #E55353;
}

.list-table {
  width: 100%;
}
.list-table.notification {
  margin: 0 0 22px;
}
.list-table.notification th {
  padding: 0 0 22px;
  color: #9A9A9A;
}
.list-table.notification th:last-child {
  text-align: right;
  width: 150px;
}
.list-table.notification td img {
  float: left;
  border-radius: 50%;
  margin: 0 15px 0 0;
  width: 50px;
  height: auto;
}
.list-table.notification td .noti-info {
  overflow: hidden;
  padding: 10px 0;
}
.list-table .switcher {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 10px;
}
.list-table th {
  padding: 0 15px 13px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  white-space: nowrap;
}
.list-table th i {
  padding: 0 5px 0 0;
}
.list-table th .th-i {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  cursor: pointer;
}
.list-table tbody tr {
  border-bottom: 12px solid #F5F5F5;
}
.list-table tbody tr:last-child {
  border: none;
}
.list-table tbody tr:hover td:after {
  display: block;
}
.list-table tbody tr td {
  padding: 22px 15px;
  background: white;
  text-align: left;
  font-size: 14px;
  line-height: 17px;
  position: relative;
  color: #808080;
}
.list-table tbody tr td:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  border: 1px solid #424FC0;
  border-width: 1px 0;
  pointer-events: none;
  display: none;
}
.list-table tbody tr td:first-child {
  border-left: none;
}
.list-table tbody tr td:first-child:after {
  border-left: 1px solid #424FC0;
}
.list-table tbody tr td:last-child {
  text-align: right;
  white-space: nowrap;
}
.list-table tbody tr td:last-child:after {
  border-right: 1px solid #424FC0;
}
.list-table tbody tr td.act {
  width: 110px;
}
.list-table tbody tr td.act a {
  text-decoration: none;
  color: #BABABA;
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  margin: 0 0 0 10px;
}
.list-table tbody tr td.act a:hover {
  color: #424FC0;
}
.list-table tbody tr td.des {
  width: 63%;
}
.list-table tbody tr td .icon-cell {
  display: inline-block;
  vertical-align: bottom;
  margin: 0 5px 0 0;
  font-size: 20px;
}
.list-table tbody tr td .icon-cell.avilable {
  color: #4FB73B;
}
.list-table tbody tr td .sm-text {
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.list-table tbody tr td .sm-text.blue {
  color: #424FC0;
}
.list-table tbody tr td .icon-access_time {
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  margin: -4px 2px 0 0;
}
.list-table tbody tr td .tag {
  display: inline-block;
  border-radius: 17px;
  padding: 5px 13px;
  background: red;
  color: white;
}
.list-table tbody tr td .tag.assigned {
  background: #424FC0;
}
.list-table tbody tr td .tag.collected {
  background: #384299;
}
.list-table tbody tr td .tag.need-assignment {
  background: #5768F9;
}
.list-table tbody tr td .tag.scheduled {
  background: #98A3FF;
}
.list-table tbody tr td .tag.delivered {
  background: #4FB73B;
}
.list-table tbody tr td .tag.not-work {
  background: #AEAEAE;
}
.list-table tbody tr td .tag.idle {
  background: #404DBD;
}
.list-table tbody tr td .tag.break {
  background: #E4BA59;
}
.list-table tbody tr td .tag.problem {
  background: #E55353;
}
.list-table tbody tr td .action {
  display: inline-block;
  vertical-align: middle;
  color: #BABABA;
  text-decoration: none;
  margin: 0 0 0 10px;
  font-size: 18px;
}
.list-table tbody tr td .action:hover {
  color: #424FC0;
}
.list-table tbody tr td .icon-star-round {
  font-size: 25px;
}
.list-table tbody tr td .icon-star-round.yellow {
  color: #ffd500;
}

.order-assigin-popup-active {
  overflow: hidden;
}
.order-assigin-popup-active .order-assigin-popup {
  right: 0;
}
.order-assigin-popup-active .order-assigin-popup .overlay {
  visibility: visible;
  opacity: 0.58;
}

.load-more {
  width: 100%;
  color: white;
  background: #2A3485;
  margin: 10px 0;
  padding: 14px;
  border: 0;
  border-radius: 6px;
}
.load-more img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-bottom: 4px;
}

.order-assigin-popup {
  position: fixed;
  right: -1574px;
  top: 0;
  height: 100vh;
  z-index: 9999;
  width: 1574px;
  transition: all 0.25s ease-in-out;
}
.order-assigin-popup .overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: #000;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.order-assigin-popup .order-assigin-content {
  background: white;
  height: 100vh;
  position: relative;
  z-index: 5;
  border-radius: 24px 0 0 24px;
}

@media screen and (max-width: 1620px) {
  .order-assigin-popup {
    width: 1450px;
  }
}
@media screen and (max-width: 1500px) {
  .order-assigin-popup {
    width: 1350px;
  }
}
@media screen and (max-width: 1400px) {
  .order-assigin-popup {
    width: 1250px;
  }
}
@media screen and (max-width: 1300px) {
  .order-assigin-popup {
    width: 1250px;
  }
}
@media screen and (max-width: 1300px) {
  .order-assigin-popup {
    width: 1200px;
  }
}
.assigin-cols {
  overflow: hidden;
  display: flex;
}
.assigin-cols .assigin-col {
  float: left;
  width: 55%;
  padding: 25px 25px 25px 20px;
  border-left: 1px solid #D4D4D4;
  height: 100vh;
}
.assigin-cols .assigin-col:first-child {
  border: none;
  width: 45%;
  padding: 25px 20px 25px 25px;
}
.assigin-cols .assigin-col .head {
  overflow: hidden;
  margin: 0 0 20px;
}
.assigin-cols .assigin-col .head h2 {
  float: left;
  color: #545454;
  font-size: 26px;
  line-height: 32px;
  margin: 0;
  font-weight: normal;
}
.assigin-cols .assigin-col .head .icon-clear {
  float: left;
  color: #545454;
  font-size: 20px;
  line-height: 32px;
  text-decoration: none;
  margin: 0 30px 0 0;
}
.assigin-cols .assigin-map {
  background: #ccc;
  height: calc(100vh - 260px);
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  margin: 0 0 20px;
}
.assigin-cols .assigin-map .find-rider {
  margin: 70px auto 0;
  background: #3E4BBB;
  border-radius: 50px;
  box-shadow: 0px 5px 9px #8F9AFF;
  font-size: 14px;
  line-height: 24px;
  padding: 14px 27px;
  color: white;
  position: absolute;
  text-decoration: none;
  z-index: 9;
  left: 40px;
  right: 40px;
  top: 0;
}
.assigin-cols .assigin-map .find-rider i {
  font-size: 24px;
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 0 0;
}

.assigner-block {
  border-radius: 12px;
  background: #F8F6F6;
  padding: 25px;
}
.assigner-block .assigner-head {
  overflow: hidden;
  margin: 0 0 16px;
}
.assigner-block .assigner-head h3 {
  float: left;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: #545454;
}
.assigner-block .assigner-head .view-link {
  float: right;
  color: #3E4BBB;
  font-size: 16px;
  line-height: 22px;
  text-decoration: underline;
  font-weight: bold;
}
.assigner-block .assiner-content {
  overflow: hidden;
}
.assigner-block .assiner-content .contact-info {
  float: left;
  width: 40%;
  padding-right: 10px;
}
.assigner-block .assiner-content .contact-info .name, .assigner-block .assiner-content .contact-info .tel {
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  color: #545454;
  margin: 0 0 5px;
}
.assigner-block .assiner-content .contact-info .tel {
  font-weight: normal;
  margin: 0;
}
.assigner-block .assiner-content .address {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  padding: 0 0 0 30px;
  float: left;
  width: 60%;
}
.assigner-block .assiner-content .address:after {
  position: absolute;
  left: 0;
  top: 0;
  font: 20px/24px icomoon;
  content: "\e909";
  color: #979797;
}

.assigner-search {
  background: #F0F0F0;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 15px;
}
.assigner-search input[type=search] {
  background: none;
  border: none;
  float: left;
  outline: none;
  font-size: 14px;
  line-height: 18px;
  padding: 15px;
}
.assigner-search .icon-magnify {
  float: right;
  padding: 0;
  background: none;
  border: none;
  font-size: 18px;
  line-height: 18px;
  margin: 15px;
  color: #B9B9B9;
  outline: none;
  cursor: pointer;
}
.assigner-search .icon-magnify:hover {
  color: #000;
}

.assign-filter-head {
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  padding: 0 25px 0 20px;
  margin: 0 -25px 14px -20px;
}
.assign-filter-head li {
  display: inline-block;
  vertical-align: top;
  margin: 0 2px 0 0;
}
.assign-filter-head li.active a {
  border-color: #3F4CBC;
}
.assign-filter-head li a {
  display: block;
  padding: 0 10px 5px;
  border-bottom: 3px solid transparent;
  color: #545454;
  text-decoration: none;
}
.assign-filter-head li a:hover {
  border-color: #3F4CBC;
}

.assigner-list {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
  overflow: auto;
  height: calc(100vh - 210px);
}
.assigner-list li {
  border: 1px solid #EDEDED;
  border-radius: 8px;
  padding: 17px 20px;
  color: #545454;
  overflow: hidden;
  margin: 0 0 8px;
  display: block;
}
.assigner-list li:hover, .assigner-list li.selected {
  border-color: #3F4CBC;
  background: #E8EAFF;
  color: #3F4CBC;
}
.assigner-list .tag {
  background: #404DBD;
  color: white;
  border-radius: 14px;
  font-size: 12px;
  line-height: 17px;
  float: right;
  padding: 6px 17px;
  margin: 5px 0 0 10px;
}
.assigner-list .tag span {
  font-weight: 700;
}
.assigner-list .info {
  overflow: hidden;
}
.assigner-list .info .name, .assigner-list .info .tel {
  display: block;
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  margin: 0 0 5px;
}
.assigner-list .info .tel {
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}

.assigner-create-btn {
  display: block;
  width: 100%;
  border-radius: 6px;
  background: #EAB75D;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  border: none;
  cursor: pointer;
  margin: 5px 0 0 0;
}
.assigner-create-btn:hover {
  opacity: 0.8;
  color: white;
  text-decoration: none;
}

.filter-button {
  width: 270px;
  background-color: #EAB75D;
  margin-top: 20px;
  color: #FFF;
  padding: 10px;
  border: none;
  bottom: 0px;
  position: fixed;
  right: 0px;
}

.selected-tag {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
  min-height: 80px;
  overflow-y: auto;
  height: calc(100vh - 475px);
}
.selected-tag li {
  float: left;
  margin: 0 10px 10px 0;
  color: white;
  background: #3A46B5;
  border-radius: 30px;
  padding: 10px 30px 10px 14px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
}
.selected-tag li a {
  position: absolute;
  right: 8px;
  top: 10px;
  color: white;
  line-height: 16px;
  text-decoration: none;
}

.filters-slide {
  background: white;
  width: 270px;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  right: -270px;
  top: 0;
  padding: 0 20px 20px;
  z-index: 91ß;
}
.filters-slide .filter-head {
  overflow: hidden;
  padding: 35px 0;
}
.filters-slide .filter-head h3 {
  float: left;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: #545454;
}
.filters-slide .filter-head .close-filter {
  float: right;
  font-size: 18px;
  line-height: 22px;
  color: #AEAEAE;
  text-decoration: none;
}
.filters-slide .filter-head .close-filter:hover {
  color: #3A46B5;
}
.filters-slide .selected-tag {
  height: auto;
  min-height: 20px;
  margin: 0 0 30px;
}

.dropdown-area {
  margin: 0 0 20px;
}

.status-panel .title {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  margin: 0 0 15px;
}
.status-panel .status-list {
  padding: 0;
  margin: 0;
  list-style: none;
  letter-spacing: -0.32em;
}
.status-panel .status-list li {
  min-width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 0 10px 20px 0;
  letter-spacing: normal;
}
.status-panel .status-list .custom-checkbox {
  margin: 0;
}
.status-panel .status-list .custom-checkbox .text {
  color: #9D9D9D;
  font-size: 12px;
}

.rider-detail-page {
  padding: 41px 30px 10px 30px;
  transition: all 0.25s ease-in-out;
}
.rider-detail-page .rider-detail-head {
  margin: 0 0 20px;
}
.rider-detail-page .rider-detail-head:after {
  display: block;
  clear: both;
  content: "";
}
.rider-detail-page .rider-detail-head .lft-head {
  float: left;
}
.rider-detail-page .rider-detail-head h2 {
  font-size: 26px;
  line-height: 32px;
  margin: 4px 0;
  color: #545454;
  float: left;
  font-weight: normal;
}
.rider-detail-page .rider-detail-head .logout-btn {
  float: right;
  margin-left: 10px;
  background-color: #E55353;
  color: white;
  border-radius: 6px;
  min-width: 193px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}
.rider-detail-page .rider-detail-head .logout-btn:before {
  content: "\e90e";
  font: 14px/20px icomoon;
  position: absolute;
  left: 20px;
  top: 10px;
}
.rider-detail-page .rider-detail-head .logout-btn.deliver {
  background: #3B48B7;
}
.rider-detail-page .rider-detail-head .logout-btn.deliver:before {
  display: none;
}

.rider-d-cols {
  margin: 0 -10px;
}
.rider-d-cols:after {
  display: block;
  clear: both;
  content: "";
}
.rider-d-cols .rider-d-col {
  width: 32%;
  padding: 0 10px 10px;
  float: left;
}
.rider-d-cols .rider-d-col:last-child {
  width: 36%;
}
.rider-d-cols .rider-info {
  background: #E4E4E4;
  border-radius: 12px;
  padding: 17px 11px 11px;
}
.rider-d-cols .rider-info .bar {
  display: block;
  margin: 0 auto 11px;
  height: 16px;
  background: white;
  border-radius: 18px;
  width: 94px;
}
.rider-d-cols .rider-info .rider-info-holder {
  background: white;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 22px;
}
.rider-d-cols .rider-info .rider-info-holder:before {
  display: block;
  background: #404DBD;
  height: 95px;
  content: "";
  margin: 0 -30px;
}
.rider-d-cols .rider-info .rider-info-holder .rider-profile-img {
  margin: -55px auto 30px;
  width: 113px;
  text-align: center;
}
.rider-d-cols .rider-info .rider-info-holder .rider-profile-img .img-holder {
  position: relative;
  margin: 0 0 9px;
}
.rider-d-cols .rider-info .rider-info-holder .rider-profile-img .img-holder img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.rider-d-cols .rider-info .rider-info-holder .rider-profile-img .img-holder .available-dot {
  position: absolute;
  top: 0;
  right: 10px;
  border-radius: 50%;
  border: 3px solid #404DBD;
  background: #4FB73B;
  width: 24px;
  height: 24px;
}
.rider-d-cols .rider-info .rider-info-holder .rider-profile-img .text {
  display: block;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  margin: 0 0 6px;
}
.rider-d-cols .rider-info .rider-info-holder dl {
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  overflow: hidden;
  margin: 0 0 33px;
}
.rider-d-cols .rider-info .rider-info-holder dl dt {
  overflow: hidden;
  font-weight: normal;
}
.rider-d-cols .rider-info .rider-info-holder dl dd {
  float: right;
  margin: 0 0 0 10px;
}

.rider-cell-info {
  background: white;
  border-radius: 12px;
  margin: 0 0 10px;
}
.rider-cell-info .verified-area {
  border-bottom: 1px solid #C6C6C6;
  padding: 39px 27px;
  overflow: hidden;
}
.rider-cell-info .verified-area .icon-cell {
  float: left;
  font-size: 70px;
  line-height: 70px;
  color: #BEBEBE;
  margin: 0 40px 0 0;
}
.rider-cell-info .verified-area .info {
  overflow: hidden;
  padding: 6px 0 4px;
}
.rider-cell-info .verified-area .info .tel {
  display: block;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 10px;
}
.rider-cell-info .verified-area .info .tag {
  background: #3B48B7;
  border-radius: 30px;
  color: white;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 23px;
}
.rider-cell-info .verified-area .info .tag i {
  font-size: 16px;
  line-height: 23px;
  padding: 0 3px 0 0;
}
.rider-cell-info .cell-footer {
  overflow: hidden;
  padding: 27px 17px;
}
.rider-cell-info .cell-footer .footer-col {
  float: left;
  width: 50%;
  padding: 0 10px;
}
.rider-cell-info .cell-footer .footer-col img {
  float: left;
  margin: 0 20px 0 0;
}
.rider-cell-info .cell-footer .footer-col info {
  overflow: hidden;
}
.rider-cell-info .cell-footer .footer-col .title {
  display: block;
  font-size: 18px;
  line-height: 18px;
  color: #545454;
}
.rider-cell-info .cell-footer .footer-col .text {
  display: block;
  color: #545454;
  font-size: 14px;
  line-height: 16px;
}

.rider-address-area {
  background: white;
  border-radius: 12px;
  padding: 27px;
  color: #545454;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 10px;
}
.rider-address-area address {
  display: block;
  font-style: normal;
  max-width: 200px;
}
.rider-address-area .title {
  display: block;
  position: relative;
  padding: 0 0 0 22px;
  margin: 0 0 22px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}
.rider-address-area .title:after {
  position: absolute;
  left: 0;
  top: 0;
  font: 14px/19px icomoon;
  content: "\e909";
}

.rider-location-area {
  background: white;
  border-radius: 12px;
  padding: 27px;
  color: #545454;
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 10px;
}
.rider-location-area .text {
  display: block;
  margin: 0 0 5px;
}
.rider-location-area .title {
  display: block;
  position: relative;
  padding: 0 0 0 36px;
  margin: 0 0 18px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
}
.rider-location-area .title:after {
  position: absolute;
  left: 0;
  top: -2px;
  box-shadow: 0px 2px 1px #0000001C;
  border: 3px solid #2E3790;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  content: "";
}
.rider-location-area .loc-link {
  display: block;
  padding: 0 20px 0 0;
  text-decoration: none;
  color: #4654DB;
  position: relative;
}
.rider-location-area .loc-link:after {
  position: absolute;
  right: 0;
  top: 0;
  font: 14px/19px icomoon;
  content: "\e909";
}

.activity-info h2 {
  font-size: 26px;
  line-height: 32px;
  color: #545454;
  margin: 0 0 30px;
  font-weight: 500;
}
.activity-info .queue-list {
  max-height: 450px;
}
.activity-info .queue-list li {
  background: white;
}
.activity-info .activity-list {
  padding: 0;
  margin: 0 0 0 30px;
  list-style: none;
  position: relative;
}
.activity-info .activity-list:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 9999px;
  border-left: 2px solid #C6C6C6;
  content: "";
}
.activity-info .activity-list li {
  margin: 0 0 18px;
  padding: 0 0 0 23px;
  position: relative;
}
.activity-info .activity-list li:before {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #C6C6C6;
  background: #F5F5F5;
  content: "";
  left: -7px;
  top: 0;
  z-index: 1;
}
.activity-info .activity-list li:first-child:before {
  display: none;
}
.activity-info .activity-list li.break > span {
  color: #B2B2B2;
}
.activity-info .activity-list li.in-progress > span {
  color: #3B48B7;
}
.activity-info .activity-list li.issue > span {
  color: #E55353;
}
.activity-info .activity-list li.completed > span {
  color: #4FB73B;
}
.activity-info .activity-list li > span {
  background: white;
  border-radius: 12px;
  font-size: 14px;
  line-height: 21px;
  padding: 10px;
  display: inline-block;
  vertical-align: top;
  color: #545454;
}
.activity-info .activity-list li > span span {
  font-weight: bold;
}
.activity-info .activity-list li > span.title {
  font-size: 16px;
  font-weight: 700;
  background: #D6D6D6;
  border-radius: 15px;
}

.order-detail-page {
  padding: 0 30px 0 0;
}
.order-detail-page:after {
  display: block;
  content: "";
  clear: both;
}
.order-detail-page .form-row {
  padding: 0 0 35px;
}
.order-detail-page .form-row.autocomplete {
  padding: 0;
  margin: 0 0 35px;
  position: relative;
}
.order-detail-page .form-row.autocomplete div {
  display: block !important;
  display: block !important;
  z-index: 99;
  max-height: 150px !important;
  overflow: auto !important;
  box-shadow: none !important;
}
.order-detail-page .form-row.autocomplete div div div {
  padding: 0 10px;
  line-height: 30px;
  font-size: 16px;
}
.order-detail-page .form-row.autocomplete input {
  border-width: 0 0 1px;
  border-color: #E8E8E8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #000000DE;
  outline: none;
}
.order-detail-page .form-row.autocomplete input + div {
  border: none;
  left: 0 !important;
  top: 100% !important;
  position: absolute !important;
  right: 0 !important;
}
.order-detail-page input[type=text], .order-detail-page input[type=password], .order-detail-page input[type=tel] {
  border-width: 0 0 1px;
  border-color: #E8E8E8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #000000DE;
  outline: none;
}
.order-detail-page label, .order-detail-page .label-text {
  display: inline-block;
  color: #0000008A;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.order-detail-page textarea {
  height: 80px;
  width: 100%;
  font-size: 12px;
  border: none;
  outline: none;
}
.order-detail-page h2 {
  font-size: 16px;
  line-height: 18px;
  margin: 0 0 49px;
  color: #000000DE;
  font-weight: normal;
}

.order-detail-right {
  float: right;
  width: 475px;
  padding: 43px 0 0;
  margin: 0 0 0 20px;
}
@media screen and (max-width: 1300px) {
  .order-detail-right {
    width: 365px;
  }
}
.order-detail-right .btn-list {
  padding: 0;
  margin: 0 0 22px -600px;
  list-style: none;
  text-align: right;
  min-height: 44px;
}
.order-detail-right .btn-list li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 11px;
}
.order-detail-right .btn-list li a {
  text-decoration: none;
}
.order-detail-right .btn-list li button {
  display: block;
  border-radius: 6px;
  background: #4F5CD3;
  padding: 10px 18px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  border: none;
  cursor: pointer;
}
.order-detail-right .btn-list li button:hover {
  opacity: 0.8;
}
.order-detail-right .btn-list li button.disable {
  cursor: not-allowed;
}
.order-detail-right .btn-list li button.red {
  background: #E55353;
}
.order-detail-right .btn-list li button.yellow {
  background: #EAB75D;
}
.order-detail-right .btn-list li button.green {
  background: #4FB73B;
}
.order-detail-right .btn-list li button.refresh {
  padding-left: 40px;
}
.order-detail-right .btn-list li button.refresh:after {
  position: absolute;
  left: 15px;
  top: 10px;
  content: "\e919";
  font: 24px/24px icomoon;
}
.order-detail-right .btn-list li button.back:after {
  position: absolute;
  left: 10px;
  top: 15px;
  content: "\e915";
  font: 24px/24px icomoon;
}
.order-detail-right .detail-map {
  height: 469px;
  background: #ccc;
  position: relative;
  margin: 0 0 16px;
}
.order-detail-right .detail-map .map-info {
  position: absolute;
  left: 30px;
  right: 30px;
  top: 56px;
  background: rgba(61, 73, 185, 0.7);
  border-radius: 25px;
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding: 14px 14px 14px 48px;
  z-index: 8;
}
.order-detail-right .detail-map .map-info:after {
  position: absolute;
  left: 20px;
  top: 15px;
  content: "\e909";
  font: 16px/20px icomoon;
}
.order-detail-right .detail-map .verified-info {
  position: absolute;
  right: 30px;
  top: 124px;
  border-radius: 25px;
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding: 8px 24px 8px 20px;
  z-index: 8;
}
.order-detail-right .detail-map .verified-info.verified-address {
  color: #198532;
  background: rgba(100, 234, 131, 0.5);
}
.order-detail-right .detail-map .verified-info.verified-address:before {
  content: "\e934";
  font: 14px icomoon;
  margin-right: 5px;
}
.order-detail-right .detail-map .verified-info.unverified-address {
  color: #B93030;
  background: rgba(231, 86, 86, 0.5);
}
.order-detail-right .detail-map .verified-info.unverified-address:before {
  content: "\e933";
  font: 14px icomoon;
  margin-right: 5px;
}

.assign-to-block {
  background: white;
  border-radius: 12px;
  padding: 35px;
  overflow: hidden;
  margin: 0 0 10px;
}
.assign-to-block .left-info {
  float: left;
  margin: 0 27px 0 0;
}
.assign-to-block .left-info h3 {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  color: #2E3790;
}
.assign-to-block .left-info .icon-biker {
  display: block;
  color: #2E3790;
  font-size: 69px;
  line-height: 70px;
}
.assign-to-block .right-info {
  overflow: hidden;
}
.assign-to-block .right-info h2 {
  margin: 0 0 12px;
  font-size: 24px;
  line-height: 29px;
  font-weight: 400;
  color: #545454;
  overflow: hidden;
}
.assign-to-block .right-info h2 .idle {
  float: right;
  background: #404DBD;
  border-radius: 11px;
  color: white;
  padding: 4px 14px;
  font-size: 12px;
  line-height: 14px;
}
.assign-to-block .right-info .tel {
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin: 0 0 18px;
}
.assign-to-block .right-info .tel .icon-cell {
  float: right;
  font-size: 14px;
  color: #4FB73B;
  line-height: 17px;
}
.assign-to-block .right-info .link {
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  line-height: 19px;
  color: #2E3790;
  text-decoration: underline;
}
.assign-to-block .right-info .link:hover {
  text-decoration: none;
}

.order-detail-left {
  overflow: hidden;
  padding: 46px 0 10px 30px;
}
.order-detail-left h1 {
  margin: 0 0 10px;
  color: #545454;
  font: 400 26px/32px Roboto;
}
.order-detail-left .breadcrumb-page {
  margin: 0 0 45px;
}

.detail-steps {
  margin: 0 0 30px;
  padding: 0;
  list-style: none;
}
.detail-steps:after {
  display: block;
  clear: both;
  content: "";
}
.detail-steps li {
  float: left;
  width: 20%;
  position: relative;
}
.detail-steps li:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 33px;
  border-bottom: 1px dashed #B1B1B1;
  content: "";
}
.detail-steps li:last-child:after {
  display: none;
}
.detail-steps li.active .title {
  color: #3D4ABA;
}
.detail-steps li.active .ico-holder:after {
  display: block;
}
.detail-steps li.active .ico-holder img.show {
  display: block;
}
.detail-steps li.active .ico-holder img.hide {
  display: none;
}
.detail-steps li.done:after {
  border-bottom: 1px solid #3D4ABA;
}
.detail-steps li.done .title {
  color: #3D4ABA;
}
.detail-steps li.done .ico-holder img.show {
  display: block;
}
.detail-steps li.done .ico-holder img.hide {
  display: none;
}
.detail-steps li .ico-holder {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: relative;
  margin: 0 0 20px;
}
.detail-steps li .ico-holder:after {
  position: absolute;
  left: -9px;
  top: -9px;
  right: -9px;
  bottom: -9px;
  content: "";
  background: #ABB0F3;
  border-radius: 50%;
  z-index: 1;
  display: none;
}
.detail-steps li .ico-holder:before {
  position: absolute;
  left: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  content: "";
  background: white;
  border-radius: 50%;
  z-index: 2;
}
.detail-steps li .ico-holder img {
  display: block;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 3;
}
.detail-steps li .ico-holder img.show {
  display: none;
}
.detail-steps li .ico-holder img.hide {
  display: block;
}
.detail-steps li .title {
  display: block;
  color: #B1B1B1;
  font-size: 14px;
  line-height: 17px;
}

.detail-cols {
  overflow: hidden;
  margin: 0 -8px;
}
.detail-cols .detail-col {
  float: left;
  width: 58%;
  padding: 0 8px;
}
@media screen and (max-width: 1300px) {
  .detail-cols .detail-col {
    width: 50%;
  }
}
.detail-cols .detail-col:first-child {
  width: 42%;
}
@media screen and (max-width: 1300px) {
  .detail-cols .detail-col:first-child {
    width: 50%;
  }
}

.pick-block {
  background: white;
  border-radius: 12px;
  padding: 30px;
  overflow: hidden;
  margin: 0 0 17px;
}
.pick-block .time-graph {
  float: right;
  width: 73px;
  height: 73px;
  margin: 0 0 0 20px;
}
.pick-block .info {
  overflow: hidden;
}
.pick-block .info span {
  display: block;
  color: #808080;
}
.pick-block .info .title {
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 8px;
}
.pick-block .info .time {
  font-size: 24px;
  line-height: 29px;
  margin: 0 0 5px;
}
.pick-block .info .day {
  font-size: 16px;
  line-height: 19px;
}

.customer-block {
  background: white;
  border-radius: 12px;
  padding: 30px;
  overflow: hidden;
}
.customer-block h2 {
  font: 700 16px/19px Roboto;
  margin: 0 0 17px;
  color: #545454;
}
.customer-block h3 {
  font: 400 26px/32px Roboto;
  margin: 0 0 17px;
  color: #545454;
}
.customer-block .gender-info {
  overflow: hidden;
  color: #545454;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px dashed #ccc;
  padding: 0 0 15px;
  margin: 0 0 20px;
}
.customer-block .gender-info .gender {
  float: left;
}
.customer-block .gender-info .age {
  float: right;
}
.customer-block .tel {
  text-align: right;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  margin: 0 0 18px;
  color: #545454;
}
.customer-block .tel .icon-cell {
  float: left;
  font-size: 22px;
  color: #545454;
  line-height: 22px;
}
.customer-block .mail {
  text-align: right;
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  font-weight: bold;
  margin: 0 0 20px;
  color: #545454;
}
.customer-block .mail .icon-email {
  float: left;
  font-size: 14px;
  color: #545454;
  line-height: 22px;
}
.customer-block .last-order {
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;
  color: #909090;
  background: #F8F6F6;
  border-radius: 4px;
  padding: 20px;
}
@media screen and (max-width: 1300px) {
  .customer-block .last-order {
    font-size: 12px;
  }
}
.customer-block .last-order .date {
  float: right;
}
.customer-block .last-order .title {
  float: left;
}

.com-block {
  background: white;
  border-radius: 12px;
  padding: 30px 30px 30px;
  overflow: hidden;
  margin: 0 0 17px 0;
}
.com-block .head {
  overflow: hidden;
  margin: 0 0 20px;
}
.com-block .head h2 {
  font: 700 16px/19px Roboto;
  margin: 0;
  color: #545454;
  float: left;
}
.com-block .head .link {
  float: right;
  font-size: 16px;
  line-height: 19px;
  color: #2E3790;
  text-decoration: none;
}
@media screen and (max-width: 1300px) {
  .com-block .head .link {
    font-size: 12px;
  }
}
.com-block .head .link:hover {
  color: #424FC0;
}
.com-block .com-list {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 30px;
  overflow: auto;
}
.com-block .com-list li {
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 20px;
  color: #A3A3A3;
}
.com-block .com-list li p {
  margin: 0;
}
.com-block .com-list li .time {
  display: block;
  font-size: 12px;
}
.com-block .frustration-meter-container {
  margin: 0 30px;
}
.com-block .frustration-meter {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.com-block .frustration-meter .icon-holder {
  width: 15%;
  margin-left: 10px;
  margin-right: 10px;
}
.com-block .frustration-meter .icon-holder img {
  max-width: 100%;
}
.com-block .frustration-meter .frustration-meter-bar {
  border-radius: 8px;
  background: #E5E5E5;
  margin-right: 10px;
  margin-left: 10px;
  width: 5%;
}
.com-block .frustration-meter .frustration-meter-bar.happy {
  background: #BBF29B;
}
.com-block .frustration-meter .frustration-meter-bar.angry {
  background: #F95428;
}

.order-detail-block {
  background: white;
  border-radius: 12px;
  padding: 30px 30px 0;
  overflow: hidden;
  margin: 0 0 17px;
}
.order-detail-block .head {
  overflow: hidden;
  margin: 0 0 20px;
}
.order-detail-block .head h2 {
  font: 700 16px/19px Roboto;
  margin: 0;
  color: #545454;
  float: left;
}
.order-detail-block .head .link {
  float: right;
  font-size: 16px;
  line-height: 19px;
  color: #2E3790;
  text-decoration: underline;
}
@media screen and (max-width: 1300px) {
  .order-detail-block .head .link {
    font-size: 12px;
  }
}
.order-detail-block .head .link:hover {
  text-decoration: none;
}
.order-detail-block .footer {
  background: #C7CBFF;
  padding: 12px 30px;
  font: 400 14px/17px Roboto;
  margin: 0 -30px;
  color: #404DBD;
  overflow: hidden;
}
.order-detail-block .footer .title {
  float: left;
}
.order-detail-block .footer .total {
  float: right;
  font-weight: bold;
}

.test-list-detail {
  padding: 0;
  margin: 0;
  list-style: none;
  height: 362px;
  overflow-y: auto;
}
.test-list-detail li {
  margin: 0 0 5px;
  padding: 20px;
  background: #F8F6F6;
  font-size: 14px;
  line-height: 17px;
}

.assign-order-block {
  border-radius: 16px;
  background: #404DBD;
  overflow: hidden;
  padding: 16px 18px 0;
  margin: 0 0 17px;
  position: relative;
}
.assign-order-block .holder {
  overflow: hidden;
  padding-bottom: 16px;
}
.assign-order-block .holder img {
  background: white;
  border-radius: 50%;
  padding: 7px;
  float: left;
  margin: 0 22px 0 0;
}
.assign-order-block .holder i {
  position: absolute;
  right: 15px;
  top: 45px;
  color: #2A3485;
  font-size: 33px;
}
.assign-order-block .holder i.yellow {
  color: #ffd500;
}
.assign-order-block .holder .info {
  overflow: hidden;
  padding: 15px 0 0;
}
.assign-order-block .holder .info .title {
  display: block;
  color: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  margin: 0 0 10px;
}
.assign-order-block .holder .info .link {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 17px;
  color: #ABB0F3;
  text-decoration: none;
  cursor: pointer;
}
.assign-order-block .holder .info .link:after {
  font: 17px/17px icomoon;
  content: "\e914";
  display: inline-block;
  vertical-align: top;
}
.assign-order-block .holder .info .link:hover {
  color: white;
}
.assign-order-block .white-block {
  background: #fff;
  margin: 0 -18px;
  padding: 16px 18px;
}
.assign-order-block .white-block .no-ratting {
  font-size: 14px;
  line-height: 17px;
}
.assign-order-block .white-block .no-ratting span {
  display: block;
}
.assign-order-block .white-block .no-ratting span.title {
  color: #808080;
}
.assign-order-block .white-block .no-ratting span.text {
  color: #2E3790;
}
.assign-order-block .white-block .view {
  float: right;
  font-size: 14px;
  line-height: 21px;
  color: #2E3790;
}
.assign-order-block .white-block .ratting-row {
  margin: 0;
}
.assign-order-block .white-block .ratting-row .point {
  margin: 0 0 0 3px;
}
.assign-order-block .white-block .full-star-ratting li {
  margin: 0 2px 0 0;
}

.customer-loc-cols {
  overflow: hidden;
}
.customer-loc-cols .customer-map {
  position: relative;
  background: #ccc;
  height: calc(100vh - 127px);
  width: calc(100% - 490px);
  float: left;
}
.customer-loc-cols .order-address {
  float: left;
  width: 490px;
}

.order-history-active .customer-history {
  opacity: 1;
  visibility: visible;
}

.customer-detail-page {
  padding: 30px 30px 15px 30px;
  transition: all 0.25s ease-in-out;
}
.customer-detail-page .customer-detail-head {
  margin: 0 0 20px;
}
.customer-detail-page .customer-detail-head:after {
  display: block;
  clear: both;
  content: "";
}
.customer-detail-page .customer-detail-head .lft-head {
  float: left;
}
.customer-detail-page .customer-detail-head h2 {
  font-size: 26px;
  line-height: 32px;
  margin: 4px 0;
  color: #545454;
  float: left;
  font-weight: normal;
}
.customer-detail-page .customer-detail-head .logout-btn {
  float: right;
  background-color: #4F5CD3;
  color: white;
  border-radius: 6px;
  min-width: 171px;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  position: relative;
}
.customer-detail-page .customer-detail-head .logout-btn:hover {
  opacity: 0.8;
}

.customer-cols {
  display: flex;
}
.customer-cols .customer-col {
  width: 33.333%;
  min-height: 200px;
}
.customer-cols .customer-col.map {
  background: #ccc;
  position: relative;
}

.customer-detail-col {
  padding: 0 8px 0 0;
}
.customer-detail-col .cust-detail-block {
  background: white;
  border-radius: 12px;
  padding: 30px 30px 1px;
  margin: 0 0 16px;
}
.customer-detail-col .cust-detail-block .head {
  overflow: hidden;
  margin: 0 0 30px;
}
.customer-detail-col .cust-detail-block .head h2 {
  margin: 0;
  font-size: 26px;
  line-height: 32px;
  font-weight: normal;
  float: left;
  color: #545454;
}
.customer-detail-col .cust-detail-block .head .icon-settings {
  float: right;
  font-size: 22px;
  line-height: 22px;
  color: #545454;
  opacity: 0.7;
  text-decoration: none;
  margin: 6px 0 0;
}
.customer-detail-col .cust-detail-block .head .icon-settings:hover {
  opacity: 1;
}
.customer-detail-col .cust-detail-block dl {
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  overflow: hidden;
  margin: 0 0 30px;
}
.customer-detail-col .cust-detail-block dl strong {
  font-weight: bold;
}
.customer-detail-col .cust-detail-block dl dt {
  overflow: hidden;
  font-weight: normal;
}
.customer-detail-col .cust-detail-block dl dd {
  float: right;
  margin: 0 0 0 10px;
}
.customer-detail-col .cust-order-block {
  background: white;
  border-radius: 12px;
  padding: 22px 22px 15px;
}
.customer-detail-col .cust-order-block .head {
  overflow: hidden;
  margin: 0 0 16px;
}
.customer-detail-col .cust-order-block .head h2 {
  margin: 0;
  font-size: 16px;
  line-height: 19px;
  float: left;
  color: #545454;
}
.customer-detail-col .cust-order-block .head a {
  float: right;
  font-size: 14px;
  line-height: 19px;
  color: #2E3790;
  text-decoration: underline;
}
.customer-detail-col .cust-order-block .head a:hover {
  text-decoration: none;
}
.customer-detail-col .cust-order-block .history-list {
  height: auto;
}

.customer-location {
  padding-left: 8px;
}
.customer-location .loc-block {
  background: white;
  border-radius: 12px 0 0 12px;
  padding: 22px 22px 15px;
}
.customer-location .loc-block h2 {
  font-size: 16px;
  line-height: 24px;
  color: #000000DE;
  font-weight: normal;
  margin: 0 0 20px;
}
.customer-location .order-address-list {
  height: 489px;
  margin: 0;
}

.customer-history {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s ease-in-out;
}
.customer-history:after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
  content: "";
  opacity: 0.58;
}

.history-block {
  background: #fff;
  padding: 30px 18px 18px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 11;
  width: 318px;
}
.history-block .head {
  overflow: hidden;
  margin: 0 0 30px;
}
.history-block .head h2 {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
  float: left;
  color: #545454;
}
.history-block .head .icon-clear {
  float: right;
  font-size: 20px;
  line-height: 22px;
  color: #545454;
  opacity: 0.7;
  text-decoration: none;
}
.history-block .head .icon-clear:hover {
  opacity: 1;
}

.history-list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: calc(100vh - 100px);
  overflow: auto;
}
.history-list li {
  background: #F8F6F6;
  border-radius: 10px;
  padding: 22px;
  margin: 0 0 13px;
}
.history-list li h3 {
  color: #545454;
  font-size: 16px;
  line-height: 19px;
  font-weight: normal;
  margin: 0 0 10px;
}
.history-list li .foot {
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
  color: #545454;
}
.history-list li .foot .date {
  float: left;
}
.history-list li .foot .time {
  float: right;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.58) !important;
  overflow: auto;
  z-index: 9990;
  padding: 20px;
}

.ReactModal__Content {
  position: static !important;
  margin: 0 auto !important;
  width: 914px !important;
  padding: 0 !important;
  border: none !important;
  background: none !important;
}

.small-modal {
  max-width: 400px;
}

.schedule {
  max-width: 770px;
}

.lightbox {
  background-color: white;
  border-radius: 24px;
  width: 914px;
  padding: 14px;
  font-size: 16px;
  line-height: 19px;
  color: #707070;
  overflow: hidden;
}
.lightbox.pin-activity {
  padding: 14px 14px 50px;
}
.lightbox.small-modal-con {
  width: 400px;
}
.lightbox .rider-map {
  height: 406px;
  background: #ccc;
  position: relative;
}
.lightbox h3 {
  font-size: 26px;
  line-height: 29px;
  color: #545454;
  font-weight: 400;
  margin: 0;
}
.lightbox .lightbox-close .icon-close {
  float: right;
  font-size: 30px;
  line-height: 30px;
  color: #545454;
  text-decoration: none;
  margin: 0 0 0 10px;
}
.lightbox .lightbox-close .icon-close:hover {
  color: #3F4CBC;
}
.lightbox .lightbox-head {
  overflow: hidden;
  padding: 25px 0 20px;
}
.lightbox .lightbox-head .info {
  overflow: hidden;
}
.lightbox .rider-lightbox-info {
  padding: 25px 26px 20px;
}
.lightbox .rider-lightbox-info h3 {
  padding: 0 0 0 20px;
  position: relative;
}
.lightbox .rider-lightbox-info h3:after {
  position: absolute;
  left: 0;
  top: 3px;
  font: 16px/20px icomoon;
  content: "\e909";
  color: #3F4CBC;
}
.lightbox .rider-lightbox-info p {
  margin: 0;
}

.lightbox-pin-activity {
  width: 94%;
  position: relative;
  height: 523px;
  margin: 0 25px 25px 25px;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
}
.lightbox-pin-activity .close {
  margin-left: 815px;
  margin-bottom: 15px;
  font-size: 30px;
  width: 10%;
}
.lightbox-pin-activity .map {
  width: 90%;
}

.lightbox-assign-map, .lightbox-add-address {
  width: calc(50% + 14px);
  background: #ccc;
  height: 623px;
  float: left;
  margin: -14px 0 -14px -14px;
  text-align: center;
  position: relative;
}
.lightbox-assign-map .lab-map-marker, .lightbox-add-address .lab-map-marker {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}
.lightbox-assign-map .find-rider, .lightbox-add-address .find-rider {
  margin: 70px auto 0;
  background: #3E4BBB;
  border-radius: 50px;
  box-shadow: 0px 5px 9px #8F9AFF;
  font-size: 14px;
  line-height: 24px;
  padding: 14px 27px;
  color: white;
  position: absolute;
  text-decoration: none;
  z-index: 9;
  left: 40px;
  right: 40px;
  top: 0;
}
.lightbox-assign-map .find-rider i, .lightbox-add-address .find-rider i {
  font-size: 24px;
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 0 0;
}

.lightbox-assign-right, .lightbox-add-address-right {
  float: left;
  width: 50%;
  padding: 0 16px 0 30px;
}

.lightbox-add-address-right .create-btn {
  display: block;
  width: 100%;
  border-radius: 6px;
  background: #EAB75D;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  border: none;
  cursor: pointer;
}
.lightbox-add-address-right .create-btn:hover {
  opacity: 0.8;
}
.lightbox-add-address-right .dropdown-area {
  margin: 0 0 40px;
}
.lightbox-add-address-right .dropdown-area .label-text {
  font-size: 12px;
  line-height: 16px;
  color: #0000008A;
}
.lightbox-add-address-right .dropdown-area .opener {
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #D4D4D4;
  padding-left: 0;
  color: #000000DE;
  font-size: 16px;
}
.lightbox-add-address-right .dropdown-area .opener:after {
  font-size: 20px;
}
.lightbox-add-address-right .dropdown-area .drop {
  padding: 0;
}
.lightbox-add-address-right .dropdown-area .drop li {
  margin: 0 0 1px;
}
.lightbox-add-address-right .dropdown-area .drop li a {
  color: #000000DE;
  display: block;
  padding: 5px 10px;
  background: #F8F8F8;
  text-decoration: none;
}
.lightbox-add-address-right .dropdown-area .drop li a:hover {
  background: #D4D4D4;
}
.lightbox-add-address-right .area-in {
  padding: 220px 0 40px;
}
.lightbox-add-address-right .area-in .custom-checkbox {
  padding-left: 62px;
}
.lightbox-add-address-right .area-in .custom-checkbox .text {
  font-size: 16px;
  color: #545454;
}
.lightbox-add-address-right .area-in .fake-checkbox {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  top: -13px;
}
.lightbox-add-address-right .area-in .fake-checkbox:after {
  line-height: 40px;
}

.lightbox-order-top {
  min-height: 365px;
}
.lightbox-order-top .order-detail-box {
  background: #F8F6F6;
  border-radius: 12px;
  padding: 23px 37px 10px;
  color: #545454;
  margin: 0 0 22px;
}
.lightbox-order-top .order-detail-box .title {
  display: block;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 9px;
}
.lightbox-order-top .order-detail-box .rider-name {
  display: block;
  font-size: 24px;
  line-height: 29px;
  margin: 0 0 9px;
}
.lightbox-order-top .order-detail-box .rider-cell {
  display: block;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  margin: 0 0 21px;
}
.lightbox-order-top .order-detail-box .rider-address {
  position: relative;
  font-size: 14px;
  line-height: 24px;
  font-style: normal;
  margin: 0 0 5px;
  padding: 0 0 0 20px;
}
.lightbox-order-top .order-detail-box .rider-address:after {
  position: absolute;
  left: 0;
  top: 2px;
  font: 15px/24px icomoon;
  content: "\e909";
  color: #979797;
}
.lightbox-order-top .test-dropdown-area {
  margin: 0 -37px;
  padding: 0 37px;
  border-top: 1px solid #E8E8E8;
}
.lightbox-order-top .test-dropdown-area .test-dropdown.open .opener:after {
  transform: rotate(-90deg);
}
.lightbox-order-top .test-dropdown-area .test-dropdown.open .selected-tag {
  display: block;
}
.lightbox-order-top .test-dropdown-area .opener {
  display: block;
  position: relative;
  background: #F8F8F8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 30px 10px 0;
  color: #545454;
  text-decoration: none;
}
.lightbox-order-top .test-dropdown-area .opener:after {
  position: absolute;
  right: 10px;
  top: 10px;
  font: 20px/20px icomoon;
  content: "\e90a";
  transform: rotate(90deg);
}
.lightbox-order-top .selected-tag {
  min-height: initial;
  height: auto;
  display: none;
}

.activity-lightbox {
  display: flex;
  position: relative;
  padding: 50px 0;
}
.activity-lightbox .lightbox-close {
  position: absolute;
  right: 24px;
  top: 24px;
}
.activity-lightbox .activity-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
}
.activity-lightbox .activity-left img {
  display: block;
  margin: 0 0 16px;
}
.activity-lightbox .activity-left .title {
  display: block;
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  color: #545454;
}
.activity-lightbox .activity-right {
  width: 50%;
  padding: 0 0 0 30px;
}
.activity-lightbox .activity-right .title {
  display: inline-block;
  vertical-align: top;
  background: #D6D6D6;
  border-radius: 15px;
  padding: 5px 16px;
  color: #545454;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 15px;
}
.activity-lightbox .activity-list {
  padding: 15px 0 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.activity-lightbox .activity-list:after {
  position: absolute;
  left: 0;
  top: 0;
  height: 9999px;
  border-left: 2px solid #C6C6C6;
  content: "";
}
.activity-lightbox .activity-list li {
  margin: 0 0 18px;
  padding: 0 0 0 23px;
  position: relative;
}
.activity-lightbox .activity-list li:before {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #C6C6C6;
  background: #F5F5F5;
  content: "";
  left: -7px;
  top: 0;
  z-index: 1;
}
.activity-lightbox .activity-list li.break > span {
  color: #B2B2B2;
}
.activity-lightbox .activity-list li.in-progress > span {
  color: #3B48B7;
}
.activity-lightbox .activity-list li.issue > span {
  color: #E55353;
}
.activity-lightbox .activity-list li.completed > span {
  color: #4FB73B;
}
.activity-lightbox .activity-list li.follow-up > span {
  color: #e4ba59;
}
.activity-lightbox .activity-list li > span {
  border-radius: 12px;
  font-size: 14px;
  line-height: 21px;
  display: inline-block;
  vertical-align: top;
  color: #545454;
}
.activity-lightbox .activity-list li > span span {
  font-weight: bold;
}
.activity-lightbox .activity-list li > span.text {
  font-size: 12px;
  display: block;
}

.notification-lightbox {
  position: relative;
  margin: -14px;
  display: flex;
}
.notification-lightbox .lightbox-close {
  position: absolute;
  right: 30px;
  top: 38px;
}
.notification-lightbox .noti-left {
  width: 65%;
  height: 200px;
  padding: 38px 30px;
}
.notification-lightbox .noti-right {
  width: 45%;
  background: #F6F6F6;
}

.lightbox.re-assign {
  padding: 14px 30px;
  display: block;
  border-radius: 30px;
  color: #7980D1;
  font-size: 12px;
  line-height: 24px;
}
.lightbox.re-assign textarea {
  border: 1px solid #C2C2C2;
  border-radius: 9px;
  padding: 20px;
  height: 150px;
}
.lightbox.re-assign .cl-holder:after {
  display: block;
  content: "";
  clear: both;
}
.lightbox.re-assign .datepick {
  width: 570px;
  float: left;
  margin: 0 40px 0 0;
}
.lightbox.re-assign .time-slot {
  overflow: hidden;
  margin: 0 0 -30px;
  padding: 0 0 30px 40px;
  border-left: 1px solid #DEDEDE;
}
.lightbox.re-assign .time-slot .title {
  display: block;
  font-size: 16px;
  color: #545454;
  margin: 0 0 10px;
}
.lightbox.re-assign .time-slot .time-list {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  height: 590px;
  overflow: auto;
}
.lightbox.re-assign .time-slot .time-list li {
  padding: 9px 20px;
  border: 1px solid #EDEDED;
  border-radius: 10px;
  font-size: 18px;
  line-height: 22px;
  color: #545454;
  font-weight: bold;
  margin: 0 0 10px;
}
.lightbox.re-assign .time-slot .time-list li.active, .lightbox.re-assign .time-slot .time-list li:hover {
  border-color: #3F4CBC;
  color: #3F4CBC;
  background: #E8EAFF;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView {
  max-width: 100%;
  min-height: 470px;
  justify-content: flex-start;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader {
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 15px;
  margin: 0 0 15px;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiPickersCalendarHeader-transitionContainer {
  height: 36px;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiIconButton-root {
  border: 1px solid #B3B3B3;
  border-radius: 50%;
  height: 42px;
  width: 42px;
  padding: 10px;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-switchHeader .MuiTypography-alignCenter {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #545454;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView .MuiPickersCalendarHeader-dayLabel {
  width: 78px;
  color: #9F9B9B;
  font-size: 18px;
  font-weight: 500;
}
.lightbox.re-assign .MuiPickersBasePicker-pickerView .MuiPickersDay-day {
  font-size: 18px;
  width: 50px;
  height: 50px;
  margin: 10px 17px;
  outline: none;
}

.queue-lightbox {
  display: flex;
  position: relative;
  padding: 30px 0;
}
.queue-lightbox .lightbox-close {
  position: absolute;
  right: 24px;
  top: 24px;
}
.queue-lightbox .queue-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
}
.queue-lightbox .queue-left img {
  display: block;
  margin: 0 0 16px;
}
.queue-lightbox .queue-left .title {
  display: block;
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  color: #545454;
  margin: 0 0 3px;
}
.queue-lightbox .queue-left .tel {
  display: block;
  text-align: center;
  color: #545454;
  font-size: 14px;
  line-height: 18px;
}
.queue-lightbox .queue-right {
  width: 50%;
  padding: 0 0 0 30px;
}
.queue-lightbox .queue-right h2 {
  font-size: 24px;
  line-height: 29px;
  color: #545454;
  margin: 0 0 15px;
}

.queue-list {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
  max-height: calc(100vh - 256px);
}
.queue-list li {
  background: #F5F5F5;
  border-radius: 10px;
  padding: 20px;
  margin: 0 0 9px;
}
.queue-list li.pick-off address {
  margin: 0 0 35px;
}
.queue-list li.pick-off address:before {
  top: 100%;
  bottom: auto;
}
.queue-list li.pick-off .pick {
  margin: 0;
}
.queue-list .head {
  overflow: hidden;
  margin: 0 0 10px;
}
.queue-list .head .number {
  float: left;
  font-size: 16px;
  line-height: 19px;
  color: #959595;
}
.queue-list .head .time {
  float: right;
  color: #545454;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}
.queue-list .pick {
  display: inline-block;
  vertical-align: top;
  background-color: #3D49B9;
  border-radius: 19px;
  font-size: 14px;
  line-height: 17px;
  padding: 9px 14px 9px 34px;
  color: white;
  position: relative;
  margin: 0 0 35px;
}
.queue-list .pick img {
  position: absolute;
  left: 15px;
  top: 9px;
  width: 10px;
}
.queue-list address {
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  margin: 0;
  padding: 0 0 0 34px;
  position: relative;
}
.queue-list address:after {
  position: absolute;
  left: 13px;
  top: -2px;
  font: 16px/20px icomoon;
  content: "\e909";
}
.queue-list address:before {
  position: absolute;
  bottom: 100%;
  left: 18px;
  content: "";
  border-left: 2px dashed rgba(0, 0, 0, 0.2);
  height: 35px;
}

.order-assign-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-assign-popup .queue-list {
  max-height: calc(100vh - 165px);
}
.order-assign-popup li {
  background-color: #fff;
  border: 1px solid #EDEDED;
}
.order-assign-popup li:hover {
  background-color: #E8EAFF;
}
.order-assign-popup .name {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 2px 0;
}

.lightbox-con {
  position: relative;
  padding: 10px;
}
.lightbox-con .lightbox-close {
  position: absolute;
  right: 0;
  top: 0;
}
.lightbox-con .create-btn {
  display: block;
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
  border-radius: 6px;
  background: #EAB75D;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: white;
  text-decoration: none;
  position: relative;
  border: none;
  cursor: pointer;
}
.lightbox-con .create-btn:hover {
  opacity: 0.8;
}

.feedback-lightbox {
  display: flex;
  position: relative;
  padding: 50px 0;
}
.feedback-lightbox .lightbox-close {
  position: absolute;
  right: 24px;
  top: 24px;
}
.feedback-lightbox .feedback-left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 40%;
}
.feedback-lightbox .feedback-left img {
  display: block;
  margin: 0 0 16px;
}
.feedback-lightbox .feedback-left .title {
  display: block;
  text-align: center;
  font-size: 26px;
  line-height: 34px;
  color: #545454;
}
.feedback-lightbox .feedback-right {
  width: 60%;
  padding: 0 80px 0 30px;
}
.feedback-lightbox .feedback-right h2 {
  font-size: 23px;
  line-height: 30px;
  color: #000000DE;
  margin: 0 0 20px;
  font-weight: normal;
}
.feedback-lightbox .feedback-right .note {
  display: block;
  font-style: italic;
  font-weight: 300;
  color: #878787DE;
  font-size: 12px;
  line-height: 20px;
}
.feedback-lightbox .ratting-holder {
  padding: 0 0 30px;
}

.ratting-row {
  overflow: hidden;
  margin: 0 0 16px;
}
.ratting-row .title {
  float: left;
  font-size: 16px;
  line-height: 21px;
  color: #000000DE;
}
.ratting-row .right {
  float: right;
}
.ratting-row .point {
  float: left;
  color: #222222;
  font-size: 16px;
  line-height: 21px;
  font-weight: bold;
  margin: 0 0 0 16px;
}

.full-star-ratting {
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}
.full-star-ratting li {
  float: left;
  margin: 0 0 0 8px;
}
.full-star-ratting li.full a i.fill {
  width: 100%;
}
.full-star-ratting li.half a i.fill {
  width: 50%;
}
.full-star-ratting li a {
  display: block;
  position: relative;
  color: #E2E2E2;
  text-decoration: none;
}
.full-star-ratting li a i {
  font-size: 21px;
  line-height: 21px;
}
.full-star-ratting li a i.fill {
  position: absolute;
  left: 0;
  top: 0;
  color: #EAB75D;
  overflow: hidden;
  width: 0;
}

.add-time-lightbox {
  position: relative;
  padding: 20px;
}
.add-time-lightbox .lightbox-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.add-time-lightbox h2 {
  margin: 0 0 30px;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
}
.add-time-lightbox .content-holder:after {
  display: block;
  clear: both;
  content: "";
}
.add-time-lightbox .content-holder .img-holder {
  float: left;
  width: 50%;
  padding: 20px 20px 0 0;
  text-align: center;
}
.add-time-lightbox .content-holder .img-holder img {
  display: inline-block;
  vertical-align: top;
}
.add-time-lightbox .right-area {
  float: left;
  width: 50%;
}
.add-time-lightbox .right-area .title {
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #545454;
  margin: 0 0 20px;
}
.add-time-lightbox .right-area .day {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  margin: 0 0 20px;
  padding: 0 0 0 30px;
  position: relative;
}
.add-time-lightbox .right-area .day i {
  position: absolute;
  left: 0;
  top: 0;
  color: #4F5CD3;
  font-size: 20px;
  line-height: 20px;
}
.add-time-lightbox .select-time-cols {
  margin: 0 0 65px;
}
.add-time-lightbox .select-time-cols:after {
  display: block;
  clear: both;
  content: "";
}
.add-time-lightbox .select-time-cols .input-holder {
  float: left;
  width: calc(50% - 22px);
}
.add-time-lightbox .select-time-cols .to {
  float: left;
  width: 44px;
  text-align: center;
  font-size: 14px;
  color: #545454;
  line-height: 40px;
}
.add-time-lightbox .select-time-cols input[type=text] {
  width: 100%;
  height: 40px;
  border: none;
  background: #F3F3F3;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
}
.add-time-lightbox .btns-holder {
  overflow: hidden;
}
.add-time-lightbox .btns-holder a {
  float: left;
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
  line-height: 29px;
  text-align: center;
  color: white;
  text-decoration: none;
}
.add-time-lightbox .btns-holder a:hover {
  opacity: 0.7;
}
.add-time-lightbox .btns-holder a.yellow {
  background: #EAB75D;
  width: 139px;
}
.add-time-lightbox .btns-holder a.blue {
  background: #4B57BD;
  width: 199px;
  margin: 0 0 0 13px;
}

.online-chart-lightbox {
  position: relative;
  text-align: center;
}
.online-chart-lightbox .lightbox-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.online-chart-lightbox .head {
  overflow: hidden;
  margin: -14px 0 0;
  border-bottom: 1px solid rgba(112, 112, 112, 0.27);
}
.online-chart-lightbox .head .left-holder {
  float: left;
  width: 115px;
  text-align: center;
  padding: 18px 0;
  border-right: 1px solid rgba(112, 112, 112, 0.27);
}
.online-chart-lightbox .head .left-holder img {
  display: inline-block;
  vertical-align: top;
}
.online-chart-lightbox .head .left-holder .title {
  display: block;
  font-size: 14px;
  color: #808080;
}
.online-chart-lightbox .head .rider-info {
  float: left;
  padding: 25px 20px 12px;
  color: #808080;
}
.online-chart-lightbox .head .rider-info .name {
  display: block;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 6px;
}
.online-chart-lightbox .head .rider-info .tel {
  display: block;
  font-size: 15px;
  line-height: 18px;
  color: #B4B4B4;
}
.online-chart-lightbox .head .shift-number {
  float: left;
  background: #3D49B9;
  border-radius: 16px;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 20px;
  color: #fff;
  margin: 39px 0 0;
}
.online-chart-lightbox .graph-area {
  margin: 0 -14px;
  overflow: auto;
  max-height: 350px;
}
.online-chart-lightbox .graph-area table {
  width: 100%;
}
.online-chart-lightbox .graph-area table th {
  font-size: 13px;
  color: #808080;
  font-weight: normal;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  border-left: 1px solid rgba(112, 112, 112, 0.1);
  text-align: center;
}
.online-chart-lightbox .graph-area table th:first-child {
  width: 128px;
  border-left: none;
  border-bottom: none;
}
.online-chart-lightbox .graph-area table td {
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
  border-left: 1px solid rgba(112, 112, 112, 0.1);
  text-align: center;
  font-size: 16px;
  color: #808080;
}
.online-chart-lightbox .graph-area table td:first-child {
  border-left: none;
}
.online-chart-lightbox .graph-area table td.active {
  background: rgba(61, 73, 185, 0.33);
}
.online-chart-lightbox .icon-close {
  position: absolute;
  right: 0;
  top: 0;
}
.online-chart-lightbox .body {
  text-align: center;
}
.online-chart-lightbox .body .save-btn {
  display: inline-block;
  vertical-align: top;
  border-radius: 6px;
  font-size: 16px;
  padding: 5px;
  line-height: 29px;
  text-align: center;
  color: white;
  text-decoration: none;
  margin: 20px 0 0 7px;
  min-width: 100px;
  background: #4B57BD;
}
.online-chart-lightbox .body .title {
  margin-top: 15px;
}

.popup-holder {
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  width: 420px;
  padding: 10px 0;
  overflow: hidden;
}
.popup-holder .popup-col {
  float: left;
  width: 50%;
  padding: 15px 20px;
  border-left: 1px solid rgba(112, 112, 112, 0.24);
}
.popup-holder .popup-col:first-child {
  border: none;
}
.popup-holder .popup-col h2 {
  font-size: 22px;
  line-height: 25px;
  margin: 0 0 20px;
  color: #545454;
}
.popup-holder .popup-col .title {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #545454;
  padding: 0 0 0 30px;
  font-weight: bold;
  margin: 0 0 10px;
}
.popup-holder .popup-col .title i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
}
.popup-holder .popup-col .date-holder {
  margin: 0 0 20px;
}
.popup-holder .popup-col .date-holder .date {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  color: #545454;
}
.popup-holder .popup-col .date-holder .dash {
  color: #545454;
  padding: 0 7px;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  font-weight: bold;
}
.popup-holder .btn-popup {
  margin: 0 0 6px;
  display: block;
  position: relative;
  background: #D0D0D0;
  border-radius: 8px;
  padding: 10px 20px 10px 55px;
  text-decoration: none;
  color: #545454;
  font-size: 14px;
  line-height: 20px;
}
.popup-holder .btn-popup i {
  position: absolute;
  left: 20px;
  top: 10px;
  font-size: 20px;
}
.popup-holder h3 {
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  padding: 0 0 0 30px;
  font-weight: normal;
  margin: 0 0 20px;
}
.popup-holder h3 i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 20px;
}
.popup-holder .rider-list {
  margin: 0;
  padding: 0;
  list-style: none;
  height: 168px;
  overflow: auto;
}
.popup-holder .rider-list li {
  padding: 5px 0;
  font-size: 13px;
  color: #808080;
}

.add-comment-lightbox {
  position: relative;
  padding: 20px;
}
.add-comment-lightbox .lightbox-close {
  position: absolute;
  right: 16px;
  top: 16px;
}
.add-comment-lightbox h2 {
  margin: 0 0 30px;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
}
.add-comment-lightbox .content-holder:after {
  display: block;
  clear: both;
  content: "";
}
.add-comment-lightbox .content-holder .img-holder {
  float: left;
  width: 50%;
  padding: 20px 20px 0 0;
  text-align: center;
}
.add-comment-lightbox .content-holder .img-holder img {
  display: inline-block;
  vertical-align: top;
}
.add-comment-lightbox .right-area {
  float: left;
  width: 50%;
}
.add-comment-lightbox .right-area .form-row textarea {
  border: 1px solid #C2C2C2;
  border-radius: 9px;
  padding: 20px;
  height: 150px;
}
.add-comment-lightbox .btns-holder {
  overflow: hidden;
}
.add-comment-lightbox .btns-holder a {
  float: left;
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
  line-height: 29px;
  text-align: center;
  color: white;
  text-decoration: none;
}
.add-comment-lightbox .btns-holder a:hover {
  opacity: 0.7;
}
.add-comment-lightbox .btns-holder a.blue {
  background: #4B57BD;
  width: 100%;
}
.add-comment-lightbox .btns-holder a.red {
  background: #e55353;
  width: 100%;
}

.page-table {
  display: table;
  width: 100%;
  min-height: 100vh;
  background: #fff;
  max-width: 475px;
  margin: 0 auto;
}
.page-table .page-table-head {
  display: table-header-group;
  padding: 15px 15px;
}
.page-table .page-table-head .head-holder {
  padding: 30px 15px 15px;
}
.page-table .page-table-body {
  display: table-cell;
  vertical-align: middle;
  padding: 0 15px;
}
.page-table .page-table-footer {
  display: table-footer-group;
}
.page-table .page-table-footer .footer-holder {
  padding: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  color: #000000DE;
}
.page-table .page-table-footer .footer-holder p {
  margin: 0 0 10px;
}
.page-table .page-table-footer .footer-holder a {
  color: #3D49B9;
  text-decoration: none;
}
.page-table .page-table-footer .footer-holder .sub-btn {
  width: 100%;
  background: #404DBD;
  border-radius: 6px;
  font-size: 14px;
  color: #fff;
  line-height: 44px;
  padding: 0 15px;
  border: none;
  cursor: pointer;
}
.page-table .logo-one {
  width: 114px;
  margin: 0 auto 15px;
}
.page-table .logo-one img {
  width: 100%;
  height: auto;
  display: block;
}
.page-table.live-location .page-table-head .head-holder {
  overflow: hidden;
  padding: 20px 30px;
}
.page-table.live-location .page-table-head .head-holder .logo-one {
  float: left;
  width: 60px;
  margin: 0;
}
.page-table.live-location .page-table-head .head-holder .right {
  float: right;
  color: #545454;
  font-size: 20px;
  font-weight: bold;
}
.page-table.live-location .page-table-footer .footer-holder {
  padding: 0 30px 30px;
}
.page-table.live-location .live-map {
  background: #545454;
  position: relative;
  height: 280px;
  z-index: 1;
  margin: 0 -15px;
}

.feedback-hoder {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  color: #000000DE;
}
.feedback-hoder.add {
  padding: 0 30px;
}
.feedback-hoder p {
  margin: 0 0 10px;
}
.feedback-hoder .title {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 10px;
}
.feedback-hoder img {
  display: block;
  margin: 0 auto 18px;
  width: 144px;
}
.feedback-hoder.expired img {
  width: 100%;
  max-width: 180px;
}
.feedback-hoder.expired p a {
  font-weight: bold;
  color: #3D49B9;
}

.value-feedback-hoder {
  text-align: center;
  font-size: 16px;
  line-height: 21px;
  color: #000000DE;
}
.value-feedback-hoder .feedback-title {
  margin-bottom: 30px;
}
.value-feedback-hoder p {
  margin: 0 0 15px;
}
.value-feedback-hoder h2 {
  margin: 0 0 5px;
  font-size: 26px;
  font-weight: normal;
}
.value-feedback-hoder .rider-info {
  overflow: hidden;
  background: #F8F6F6;
  border-radius: 80px;
  padding: 20px 25px;
  margin: 0 0 25px;
}
.value-feedback-hoder .rider-info i {
  float: left;
  color: #2E3790;
  line-height: 32px;
  font-size: 32px;
}
.value-feedback-hoder .rider-info .info {
  overflow: hidden;
  text-align: right;
}
.value-feedback-hoder .rider-info .info .name {
  display: block;
  color: #545454;
  font-size: 16px;
  line-height: 20px;
}
.value-feedback-hoder .rider-info .info .time {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #979797;
}
.value-feedback-hoder textarea {
  border: 1px solid #E5E5E5;
  border-radius: 12px;
  height: 132px;
  margin: 0 0 25px;
  width: 100%;
}
.value-feedback-hoder textarea ::placeholder {
  color: #4a4a4a;
}

.star-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.star-list > li {
  overflow: hidden;
  margin: 0 0 25px;
}
.star-list .text-label {
  float: left;
  color: #000000DE;
  font-size: 16px;
  line-height: 30px;
}
.star-list .ratting {
  float: right;
  padding: 0;
  margin: 0;
  list-style: none;
}
.star-list .ratting li {
  float: left;
  margin: 0 0 10px;
}
.star-list .ratting li.active a {
  color: #404DBD;
}
.star-list .ratting li.active a:before {
  content: "\e925";
}
.star-list .ratting li a {
  color: #DBDBDB;
  text-decoration: none;
}
.star-list .ratting li a:before {
  font: 30px/30px icomoon;
  content: "\e924";
}

.text-area {
  padding-left: 10px;
}

.star-list-physical-feedback-form {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid #E5E5E5;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 30px;
}
.star-list-physical-feedback-form .question {
  margin-top: 20px;
  margin-right: 30px;
}
.star-list-physical-feedback-form .question > li {
  overflow: hidden;
  margin: 0 0 20px;
}
.star-list-physical-feedback-form .question .text-label-physical-feedback-form {
  color: #4a4a4a;
  font-size: 16px;
  line-height: 30px;
}
.star-list-physical-feedback-form .question .noto-nastaliq-urdu {
  font-family: "noto-nastaliq-urdu";
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form {
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 10px;
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li {
  margin: 0 0 10px;
  flex-flow: row wrap;
  width: 20%;
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li .star-holder {
  display: flex;
  flex-flow: row wrap;
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li .star-holder .star-title {
  padding-bottom: -30px;
  flex-flow: row wrap;
  width: 100%;
  color: #DBDBDB;
  font-size: 11px;
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li.active a {
  color: #404DBD;
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li.active a:before {
  content: "\e925";
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li a {
  color: #DBDBDB;
  text-decoration: none;
  width: 100%;
}
.star-list-physical-feedback-form .question .rating-physical-feedback-form li a:before {
  font: 30px/30px icomoon;
  content: "\e924";
  font-size: 40px;
}

.live-location-info {
  margin: -20px -15px 0;
  border-radius: 16px;
  position: relative;
  z-index: 5;
  background: #fff;
  padding: 30px 30px 0;
}
.live-location-info .name, .live-location-info .title {
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.3;
  color: #545454;
  margin: 0 0 15px;
}
.live-location-info .title {
  font-size: 14px;
  margin: 0 0 15px;
}
.live-location-info .info-block {
  border-bottom: 1px dashed #D0D0D0;
  margin: 0 0 20px;
}
.live-location-info .info-list {
  display: block;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  margin: 0 0 18px;
  color: #545454;
}
.live-location-info .info-list i {
  float: left;
  font-size: 22px;
  color: #3F4CBC;
  line-height: 22px;
  width: 30px;
  text-align: center;
  margin: 0 20px 0 0;
}
.live-location-info .info-list span {
  display: block;
  overflow: hidden;
}
.live-location-info .price-list {
  overflow: hidden;
  font-size: 14px;
  line-height: 17px;
  color: #3F4CBC;
  background: #E2E5FF;
  border-radius: 8px;
  padding: 20px;
  margin: 0 0 7px;
}
.live-location-info .price-list .price {
  float: right;
  font-weight: 500;
}
.live-location-info .price-list .sub-title {
  float: left;
}
.live-location-info .price-list.total {
  background: none;
  color: #545454;
  font-size: 16px;
}
.live-location-info .price-list.total .price {
  font-weight: bold;
}
.live-location-info a {
  text-decoration: none;
  display: block;
  min-width: 161px;
  border-radius: 6px;
  background: #4F5CD3;
  padding: 15px 30px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: white;
  position: relative;
  border: none;
  cursor: pointer;
}

.schedule-content {
  padding: 0 25px 25px;
}
.schedule-content .schedule-head {
  padding: 45px 0 30px;
  position: relative;
}
.schedule-content .schedule-head h2 {
  margin: 0;
  color: #545454;
  font-size: 26px;
  line-height: 30px;
  font-weight: normal;
}
.schedule-content .schedule-head .city-dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.days-schedule {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 3px #00000014;
  overflow: hidden;
}
.days-schedule .days-schedule-head {
  padding: 22px 33px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
}
.days-schedule .days-schedule-head h3 {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin: 0;
  color: #808080;
}

.days-holder {
  display: flex;
}
.days-holder .days-col {
  min-height: 300px;
  border-left: 1px solid rgba(112, 112, 112, 0.1);
  padding: 30px 5px 0;
  width: 14.28%;
}
.days-holder .days-col:first-child {
  border: none;
}
.days-holder .days-col.current-day {
  background: #F6F6F6;
}
.days-holder .days-col.current-day .time-list li {
  background: #3B48B7;
}
.days-holder .days-col .title {
  display: block;
  color: #808080;
  font-size: 16px;
  line-height: 19px;
  margin: 0 auto 30px;
  max-width: 136px;
}
.days-holder .time-list {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
  height: calc(100vh - 364px);
  overflow: auto;
}
.days-holder .time-list li {
  margin: 0 auto 17px;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  border-radius: 21px;
  background: #7488D5;
  max-width: 182px;
  text-align: center;
  padding: 9px;
}
.days-holder .time-list li.active {
  background: #4FB73B !important;
}
.days-holder .time-list li .info {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #3B48B7;
  cursor: pointer;
}
.days-holder .add-time {
  display: block;
  text-align: center;
  font-size: 21px;
  line-height: 25px;
  color: #fff;
  max-width: 90px;
  margin: 0 auto 30px;
  background: #D0D0D0;
  border-radius: 21px;
  padding: 7px;
  text-decoration: none;
}
.days-holder .add-time:hover {
  text-decoration: none;
  background: #3B48B7;
}

.shift-update-content {
  background: #fff;
  min-height: 100vh;
}

.shift-update-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 30px 20px;
}
.shift-update-container .btns-bar {
  text-align: right;
}
.shift-update-container .btns-bar a {
  display: inline-block;
  vertical-align: top;
  border-radius: 6px;
  font-size: 16px;
  padding: 10px;
  line-height: 29px;
  text-align: center;
  color: white;
  text-decoration: none;
  margin: 0 0 0 7px;
  min-width: 137px;
}
.shift-update-container .btns-bar a:hover {
  opacity: 0.7;
}
.shift-update-container .btns-bar a.yellow {
  background: #EAB75D;
}
.shift-update-container .btns-bar a.blue {
  background: #4B57BD;
}
.shift-update-container .btns-bar a.red {
  background: #E55353;
}
.shift-update-container .shift-update-head {
  width: calc(100% - 350px);
}
.shift-update-container .shift-update-head:after {
  clear: both;
  content: "";
  display: block;
}
.shift-update-container .shift-update-head img {
  float: left;
  margin: 0 64px 0 0;
}
.shift-update-container .shift-update-head .info {
  overflow: hidden;
}
.shift-update-container .shift-update-head .info .title {
  display: block;
  border-bottom: 1px solid #E8E8E8;
  font-size: 26px;
  color: #545454;
  padding: 0 0 10px;
  margin: 0 0 22px;
}
.shift-update-container .shift-update-head .info .detail {
  margin: 0 0 20px;
}
.shift-update-container .shift-update-head .info .detail .day, .shift-update-container .shift-update-head .info .detail .city {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  margin: 0 30px 0 0;
  padding: 0 0 0 30px;
  position: relative;
}
.shift-update-container .shift-update-head .info .detail .day i, .shift-update-container .shift-update-head .info .detail .city i {
  position: absolute;
  left: 0;
  top: 0;
  color: #4F5CD3;
  font-size: 20px;
  line-height: 20px;
}
.shift-update-container .shift-update-head .info .select-time-cols {
  margin: 0 0 15px;
  max-width: 360px;
}
.shift-update-container .shift-update-head .info .select-time-cols:after {
  display: block;
  clear: both;
  content: "";
}
.shift-update-container .shift-update-head .info .select-time-cols .input-holder {
  float: left;
  width: calc(50% - 22px);
}
.shift-update-container .shift-update-head .info .select-time-cols .to {
  float: left;
  width: 44px;
  text-align: center;
  font-size: 14px;
  color: #545454;
  line-height: 40px;
}
.shift-update-container .shift-update-head .info .select-time-cols input[type=text] {
  width: 100%;
  height: 40px;
  border: none;
  background: #F3F3F3;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
}

.add-rider-input {
  margin: 0 0 20px;
}
.add-rider-input .labels {
  display: block;
  margin: 0 0 18px;
  font-size: 16px;
  font-weight: bold;
  color: #000000DE;
  line-height: 23px;
}
.add-rider-input .labels span {
  display: inline-block;
  color: #fff;
  background: #404DBD;
  font-size: 13px;
  border-radius: 12px;
  padding: 0 10px;
}
.add-rider-input .auto-fill {
  position: relative;
  z-index: 9;
}
.add-rider-input .auto-fill select {
  width: 100%;
  border: none;
  background: #F8F8F8;
  height: 40px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 20px;
}
.add-rider-input .auto-fill .auto-fill-drop {
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  margin: 10px 0 0;
}
.add-rider-input .auto-fill .rider-list {
  overflow: hidden;
  box-shadow: 0px 0 9px #00000014;
  border-radius: 0px 0px 8px 8px;
  background: #fff;
  max-height: 200px;
  overflow: auto;
  margin: 0;
  padding: 0;
  list-style: none;
}
.add-rider-input .auto-fill .rider-list li {
  padding: 14px 30px;
  letter-spacing: -0.32em;
  font-size: 14px;
  line-height: 17px;
  color: #808080;
}
.add-rider-input .auto-fill .rider-list li:hover {
  background: #F8F8F8;
}
.add-rider-input .auto-fill .rider-list li .col {
  display: inline-block;
  vertical-align: middle;
  letter-spacing: normal;
  width: calc(33.333% - 10px);
  padding: 0 10px 0 0;
}
.add-rider-input .auto-fill .rider-list li .col a {
  color: #3B48B7;
}
.add-rider-input .auto-fill .rider-list li .col .name, .add-rider-input .auto-fill .rider-list li .col .date {
  display: block;
}
.add-rider-input .auto-fill .rider-list li .col .tel, .add-rider-input .auto-fill .rider-list li .col .link {
  font-size: 12px;
  color: #B4B4B4;
}
.add-rider-input .auto-fill .rider-list li .col .link {
  color: #3B48B7;
}
.add-rider-input .auto-fill .rider-list li .action {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  text-align: right;
}
.add-rider-input .auto-fill .rider-list li .action i {
  font-size: 20px;
  line-height: 20px;
  color: #B4B4B4;
}

.six-cols {
  border-bottom: 1px solid #E8E8E8;
  padding: 0 0 10px;
}
.six-cols.add {
  border: none;
  padding: 10px 0;
  font-size: 14px;
  color: #808080;
  line-height: 17px;
}
.six-cols.add .name, .six-cols.add .date {
  display: block;
}
.six-cols.add .tel, .six-cols.add .link {
  font-size: 12px;
  color: #B4B4B4;
}
.six-cols.add .link {
  color: #3B48B7;
  text-decoration: underline;
  cursor: pointer;
}
.six-cols.add .custom-checkbox {
  height: 18px;
  margin: 0;
  width: 18px;
  padding: 0;
}
.six-cols.add select {
  background: #F3F3F3;
  border: none;
  height: 40px;
  font-size: 14px;
  color: #545454;
  padding: 0 10px;
  width: 150px;
  margin: 0;
}
.six-cols:after {
  display: block;
  clear: both;
  content: "";
}
.six-cols .col {
  float: left;
}
.six-cols .col.name {
  width: 25%;
}
.six-cols .col.entery {
  width: 25%;
}
.six-cols .col.shift {
  width: 25%;
}
.six-cols .col.spot {
  width: 25%;
}
.six-cols .col.btn-col {
  width: 25%;
}
.six-cols .col.action {
  width: 25%;
}
.six-cols .col .title {
  font-size: 13px;
  color: #545454;
  display: block;
  font-weight: bold;
}
.six-cols i.icon-minus-circle {
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  color: #B4B4B4;
}
.six-cols .save-btn {
  display: inline-block;
  vertical-align: top;
  border-radius: 6px;
  font-size: 16px;
  padding: 5px;
  line-height: 29px;
  text-align: center;
  color: white;
  text-decoration: none;
  margin: 0 0 0 7px;
  min-width: 100px;
  background: #4B57BD;
  outline: none;
  border: none;
  cursor: pointer;
}
.six-cols .save-btn.disabled {
  background: #D3D3D3;
}
.six-cols .select-time-cols {
  max-width: 360px;
}
.six-cols .select-time-cols:after {
  display: block;
  clear: both;
  content: "";
}
.six-cols .select-time-cols .input-holder {
  float: left;
  width: calc(50% - 22px);
}
.six-cols .select-time-cols .to {
  float: left;
  width: 44px;
  text-align: center;
  font-size: 14px;
  color: #545454;
  line-height: 40px;
}
.six-cols .select-time-cols input[type=text] {
  width: 100%;
  height: 40px;
  border: none;
  background: #F3F3F3;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
}

.shift-table table {
  width: 100%;
}
.shift-table table i.icon-minus-circle {
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  color: #B4B4B4;
}
.shift-table table thead th {
  font-size: 13px;
  color: #545454;
  padding: 0 10px 20px;
  border-bottom: 1px solid #E8E8E8;
}
.shift-table table thead th:first-child {
  padding-left: 0;
  width: 28px;
}
.shift-table table thead th:last-child {
  text-align: right;
  width: 25px;
}
.shift-table table thead th img {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
.shift-table table thead th .select-btn {
  display: block;
  width: 18px;
  height: 18px;
  background: #404DBD;
  color: #fff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  letter-spacing: -1px;
}
.shift-table table td {
  padding: 20px 10px;
  font-size: 14px;
  color: #808080;
  line-height: 17px;
}
.shift-table table td:first-child {
  padding-left: 0;
}
.shift-table table td .name, .shift-table table td .date {
  display: block;
}
.shift-table table td .tel, .shift-table table td .link {
  font-size: 12px;
  color: #B4B4B4;
}
.shift-table table td .link {
  color: #3B48B7;
  text-decoration: underline;
  cursor: pointer;
}
.shift-table table td .custom-checkbox {
  height: 18px;
  margin: 0;
  width: 18px;
  padding: 0;
}
.shift-table table td select {
  background: #F3F3F3;
  border: none;
  height: 40px;
  font-size: 14px;
  color: #545454;
  padding: 0 10px;
  width: 192px;
}

.attendance-content {
  padding: 0 28px 10px;
}
.attendance-content .attendance-head {
  background: #3D49B9;
  padding: 0 28px 97px;
  margin: 0 -28px;
}
.attendance-content .top-head {
  padding: 41px 196px 0 0;
  position: relative;
  margin: 0 0 34px;
}
.attendance-content .top-head:after {
  display: block;
  clear: both;
  content: "";
}
.attendance-content .city-dropdown {
  position: absolute;
  right: 0;
  top: 0;
}
.attendance-content h2 {
  color: white;
  font-size: 26px;
  line-height: 38px;
  padding: 5px 0;
  float: left;
  width: 245px;
  font-weight: normal;
  margin: 0;
}
.attendance-content .right {
  float: left;
  width: calc(100% - 245px);
}
.attendance-content .right select {
  float: right;
  color: white;
  border: none;
  font-size: 16px;
  height: 48px;
  background: #2E3790;
  border-radius: 6px;
  padding: 0 10px;
  width: 40%;
  margin: 0;
}
.attendance-content .right .date-holder {
  float: left;
  width: 60%;
  padding: 0 18px 0 0;
}
.attendance-content .right .date-holder .input-holder {
  float: left;
  width: calc(50% - 15px);
  position: relative;
}
.attendance-content .right .date-holder .input-holder i {
  position: absolute;
  right: 15px;
  font-size: 20px;
  color: #fff;
  line-height: 20px;
  top: 14px;
  pointer-events: none;
  z-index: 9;
}
.attendance-content .right .date-holder .input-holder .date-picker > div {
  display: block;
}
.attendance-content .right .date-holder .input-holder .date-picker > div > div {
  display: block;
}
.attendance-content .right .date-holder .input-holder .date-picker > div > div:after, .attendance-content .right .date-holder .input-holder .date-picker > div > div:before {
  display: none;
}
.attendance-content .right .date-holder .input-holder input[type=text] {
  color: white;
  border: none;
  font-size: 16px;
  line-height: 18px;
  height: 48px;
  background: #2E3790;
  border-radius: 6px;
  padding: 15px 40px 15px 10px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
}
.attendance-content .right .date-holder .input-holder input[type=text]::-webkit-input-placeholder {
  color: #fff;
}
.attendance-content .right .date-holder .input-holder input[type=text]::-moz-placeholder {
  opacity: 1;
  color: #fff;
}
.attendance-content .right .date-holder .input-holder input[type=text]:-moz-placeholder {
  color: #fff;
}
.attendance-content .right .date-holder .input-holder input[type=text]:-ms-input-placeholder {
  color: #fff;
}
.attendance-content .right .date-holder .input-holder input[type=text].placeholder {
  color: #fff;
}
.attendance-content .right .date-holder .dash {
  float: left;
  width: 30px;
  text-align: center;
}
.attendance-content .right .date-holder .dash:after {
  margin: 20px 0 0;
  display: inline-block;
  vertical-align: top;
  content: "";
  width: 13px;
  border-bottom: 7px solid #2E3790;
}
.attendance-content .rider-bar:after {
  clear: both;
  content: "";
  display: block;
}
.attendance-content .rider-bar .download-btn {
  float: right;
  width: 161px;
  background: #EAB75D;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  line-height: 21px;
  padding: 14px 10px 14px 44px;
  text-decoration: none;
  position: relative;
}
.attendance-content .rider-bar .download-btn i {
  position: absolute;
  left: 20px;
  top: 14px;
  font-size: 17px;
  line-height: 20px;
}
.attendance-content .rider-bar select {
  float: left;
  width: calc(100% - 194px);
  background: #fff;
  height: 49px;
  border: none;
  border-radius: 6px;
  color: #5E5E5E;
  font-size: 16px;
  margin: 0;
  padding: 0 10px;
}

.attendance-table {
  margin: -75px 0 0;
}
.attendance-table .list-table th {
  color: #fff;
}
.attendance-table .list-table td {
  font-size: 12px;
}
.attendance-table .list-table td:last-child {
  text-align: left;
}
.attendance-table .list-table td .icon-dashbord {
  text-decoration: none;
  font-size: 18px;
}
.attendance-table .list-table td .name, .attendance-table .list-table td .time {
  display: block;
  font-size: 14px;
  color: #545454;
}
.attendance-table .list-table td .on-time, .attendance-table .list-table td .start {
  display: inline-block;
  vertical-align: top;
  background: #4FB73B;
  border-radius: 11px;
  font-size: 11px;
  line-height: 21px;
  padding: 0 10px;
  color: #fff;
}
.attendance-table .list-table td .on-time.off, .attendance-table .list-table td .start.off {
  background: #D03434;
}
.attendance-table .list-table td .start {
  color: #7D7D7D;
  background: #DCDCDC;
  vertical-align: middle;
}

.MuiPopover-root {
  z-index: 9999 !important;
}

.password-reset-page {
  min-height: 100vh;
  position: relative;
  background: #1d2784;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 15px;
}
.password-reset-page:after {
  background: url(../images/graphic.png) no-repeat 50% 50%;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 1;
  opacity: 0.4;
}

.password-panel {
  width: 100%;
  max-width: 498px;
  background: white;
  position: relative;
  z-index: 7;
  border-radius: 20px;
  padding: 50px 44px;
}
.password-panel .form-holder h1 {
  color: #2E3790;
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 50px;
  text-align: center;
}
.password-panel .password-reset-logo {
  margin: 0 100px 10px 135px;
  width: 84px;
}
.password-panel .password-reset-logo img {
  display: block;
  height: auto;
  width: 125px;
}
.password-panel .login-footer {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #8A8A8A;
  text-align: center;
  padding: 0 15px;
}

.login-form .login-btn {
  width: 100%;
  font: 16px/53px Roboto;
  color: white;
  text-transform: uppercase;
  height: 53px;
  background: #2E3790;
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  margin: 17px 0 20px;
  border: none;
}
.login-form .login-btn:hover {
  background: #1c2263;
}

.order-detail-popup-active .order-detail-popup {
  right: 0;
}
.order-detail-popup-active .overlay-order-popup {
  opacity: 0.58;
  visibility: visible;
}

.overlay-order-popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: #000;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 998;
}

.prescription-image-container {
  position: fixed;
  right: 500px;
  left: 0px;
  overflow: auto;
  top: 0;
  height: 100vh;
  width: auto;
  z-index: 999;
  padding: 30px;
  transition: all 0.25s ease-in-out;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.prescription-image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 9px;
  vertical-align: super;
}

.prescription-image-container object {
  border-radius: 9px;
  vertical-align: super;
}

.order-detail-popup {
  background: white;
  position: fixed;
  right: -500px;
  top: 0;
  height: 100vh;
  width: 500px;
  z-index: 999;
  padding: 30px;
  transition: all 0.25s ease-in-out;
}
.order-detail-popup .value {
  position: relative;
}
.order-detail-popup .value .rs {
  position: absolute;
  right: 0;
  top: 0;
  color: #000;
  opacity: 0.46;
  font-size: 16px;
  font-weight: bold;
}
.order-detail-popup .value.select {
  border-bottom: 1px solid #E8E8E8;
}
.order-detail-popup .value.select select {
  background: none;
  border: none;
  margin: 0 0 0 -6px;
}
.order-detail-popup .head {
  overflow: hidden;
  margin: 0 0 20px;
}
.order-detail-popup .head h2 {
  font-size: 18px;
  line-height: 22px;
  color: #545454;
  margin: 0;
  float: left;
}
.order-detail-popup .head .link {
  float: right;
  font-size: 16px;
  line-height: 19px;
  color: #BABABA;
  text-decoration: none;
}
.order-detail-popup .head .link:hover {
  color: #424FC0;
}
.order-detail-popup .head .close-popup {
  float: right;
  color: #545454;
  text-decoration: none;
  font-size: 22px;
}
.order-detail-popup .head .close-popup:hover {
  color: #4B57BD;
}
.order-detail-popup .test-list {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
  height: calc(100vh - 450px);
  overflow: auto;
}
.order-detail-popup .test-list li {
  margin: 0 0 8px;
  background: #E8EAFF;
  border-radius: 40px;
  padding: 10px 20px;
  line-height: 20px;
  font-size: 14px;
  color: #3F4CBC;
}
.order-detail-popup .test-list li .text {
  overflow: hidden;
  display: block;
}
.order-detail-popup .test-list li .delete-test {
  float: right;
  color: #3F4CBC;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
  margin: 0 0 0 10px;
}
.order-detail-popup .total-price {
  overflow: hidden;
  background: #F0F0F0;
  padding: 20px 30px;
  margin: 0 -30px;
  font-size: 20px;
  font-weight: bold;
  color: #1D1D1D;
  margin: 0 0 24px;
}
.order-detail-popup .total-price .title {
  float: left;
}
.order-detail-popup .total-price .des {
  float: right;
}
.order-detail-popup .submit-btn {
  width: 100%;
  font: 16px/53px Roboto;
  color: white;
  text-transform: uppercase;
  height: 53px;
  background: #4B57BD;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  border: none;
}
.order-detail-popup .submit-btn:hover {
  background: #1c2263;
}

.reports-container {
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
}
.reports-container .nav-holder {
  flex-flow: row wrap;
  width: 5%;
}
.reports-container .report-holder {
  width: 95%;
}

#root {
  overflow: hidden;
}

#wrapper {
  position: relative;
  width: 100%;
}
#wrapper:after {
  display: block;
  clear: both;
  content: "";
}
#wrapper.inner-page {
  padding-left: 81px;
}

.view-more {
  display: inline-block;
  font-size: 14px;
  line-height: 18px;
  color: #2E3790;
  text-decoration: none;
}
.view-more:after {
  content: "\e90a";
  font: 18px/18px icomoon;
  padding-left: 5px;
  display: inline-block;
  vertical-align: top;
}

.form-row {
  padding: 0 0 25px;
}
.form-row input[type=text], .form-row input[type=password], .form-row input[type=tel], .form-row textarea {
  border-width: 0 0 1px;
  border-color: #E8E8E8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #000000DE;
  outline: none;
}
.form-row textarea {
  height: 100px;
  line-height: 22px;
}
.form-row .no-editable {
  border-bottom: 1px solid #E8E8E8;
  width: 100%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 16px;
  color: #9e9c9c;
}
.form-row label {
  display: inline-block;
  color: #0000008A;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}
.form-row label + div {
  display: block;
  width: 100%;
}
.form-row label + div > div {
  display: block;
  width: 100%;
  border-bottom: 1px solid #E8E8E8;
}
.form-row label + div > div:before, .form-row label + div > div:after {
  display: none;
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  padding: 0 0 0 32px;
}
.custom-checkbox input {
  position: fixed;
  left: -9999px;
  top: -9999px;
}
.custom-checkbox input:checked ~ .fake-checkbox {
  border-color: #3F4CBC;
  background: #3F4CBC;
}
.custom-checkbox input:checked ~ .fake-checkbox:after {
  display: block;
}
.custom-checkbox .text {
  color: #000000DE;
  font-size: 16px;
  line-height: 18px;
}
.custom-checkbox .fake-checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #D4D4D4;
}
.custom-checkbox .fake-checkbox:after {
  font: 14px/16px icomoon;
  content: "\e913";
  display: block;
  text-align: center;
  color: white;
}

.switcher {
  width: 44px;
  height: 24px;
  display: block;
  margin: 0 auto;
  position: relative;
}
.switcher input {
  position: fixed;
  left: -9999px;
  top: -9999px;
}
.switcher input:checked ~ .button {
  background: #2E3790;
}
.switcher input:checked ~ .button:after {
  left: 22px;
}
.switcher .button {
  position: relative;
  background: #c9c9c9;
  border-radius: 40px;
  height: 24px;
  display: block;
  transition: all 0.25s ease-in-out;
}
.switcher .button:after {
  width: 20px;
  height: 20px;
  background: white;
  position: absolute;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  transition: all 0.25s ease-in-out;
  content: "";
}

.breadcrumb-page {
  padding: 0;
  margin: 0;
  list-style: none;
  letter-spacing: -0.32em;
}
.breadcrumb-page li {
  letter-spacing: normal;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #929292;
  display: inline-block;
  vertical-align: top;
}
.breadcrumb-page li:before {
  content: "/";
  padding: 0 2px;
}
.breadcrumb-page li:first-child:before {
  display: none;
}
.breadcrumb-page li a {
  color: #929292;
  font-weight: normal;
}

.dropdown {
  position: relative;
}
.dropdown.open .opener:after {
  transform: rotate(-90deg);
}
.dropdown.open .drop {
  display: block;
}
.dropdown.top .drop {
  top: auto;
  bottom: 100%;
}
.dropdown .label-text {
  display: block;
  font-size: 14px;
  line-height: 18px;
  color: #545454;
  margin: 0 0 15px;
}
.dropdown .opener {
  display: block;
  position: relative;
  background: #F8F8F8;
  border-radius: 5px;
  font-size: 12px;
  line-height: 20px;
  padding: 10px 30px 10px 13px;
  color: #959595;
  text-decoration: none;
}
.dropdown .opener:after {
  position: absolute;
  right: 10px;
  top: 10px;
  font: 16px/20px icomoon;
  content: "\e90a";
  transform: rotate(90deg);
}
.dropdown .drop {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  padding: 22px 22px 5px;
  margin: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 99;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  background: white;
  display: none;
}
.dropdown .drop li {
  margin: 0 0 15px;
}
.dropdown .drop .custom-checkbox {
  margin: 0;
}
.dropdown .drop .custom-checkbox .text {
  color: #9D9D9D;
  font-size: 12px;
}

select {
  display: block;
  width: 100%;
  margin: 8px 0 0;
  font-size: 14px;
  height: 30px;
}

.order-address-list {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
  height: calc(100vh - 300px);
  overflow-y: auto;
}
.order-address-list li {
  border: 1px solid #D0D0D0;
  margin: 0 0 12px;
  border-radius: 10px;
  padding: 21px 42px 21px 21px;
  font-size: 14px;
  line-height: 24px;
  color: #545454;
  position: relative;
}
.order-address-list li .address-badge {
  pointer-events: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.order-address-list li .address-badge img {
  margin-right: 10px;
}
.order-address-list li.current {
  border-color: #3F4CBC;
  background: #E8EAFF;
  color: #3F4CBC;
}
.order-address-list li.current:after {
  position: absolute;
  right: 24px;
  top: 50%;
  font: 18px/18px icomoon;
  content: "\e909";
  margin: -9px 0 0;
}
.order-address-list li .cancel {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000;
  font-size: 18px;
  text-decoration: none;
}
.order-address-list li .cancel:hover {
  color: #0275d8;
}

.gm-style .gm-style-iw-c {
  background: none;
  padding: 0;
  box-shadow: none;
}

.gm-style .gm-style-iw-t:after {
  display: none;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}

.gm-style .gm-style-iw-c > button {
  right: 0 !important;
  top: 0 !important;
  opacity: 1;
}
.gm-style .gm-style-iw-c > button:after {
  content: "\e916";
  color: white;
  font: 16px/18px icomoon;
}
.gm-style .gm-style-iw-c > button img {
  display: none !important;
}

.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  margin: 1em auto;
  text-align: left;
  position: absolute;
  z-index: 4;
  left: 80px;
  top: 70px;
  right: 80px;
  width: auto;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: 0.5em 1em;
  outline: none;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
  transition: border 0.2s, box-shadow 0.2s;
}

.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}

/* Google Place Autocomplete */
.pac-container {
  z-index: 2000001 !important;
}
